import {Box, useTheme} from '@mui/material';

function PageCardToolbar({children}) {

  const theme = useTheme();

  return (
      <Box
          sx={{
            px: 2,
            pt:.5,
            boxShadow: `inset 0 -2px 0 0 ${theme.palette.grey[200]}`,
          }}
      >
        {children}
      </Box>
  );
}

export default PageCardToolbar;
