import axios from 'axios';

export async function fileUpload_POST({
                                        files = [],
                                        cbSuccess = (res) => {
                                        },
                                        cbFail = (error) => {
                                        },
                                      }) {
  try {

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append('image', file);
    });

    const response = await axios.post('assets/upload-image', formData);

    cbSuccess(response);

  } catch (e) {
    cbFail(e);
  }
}

export async function file_DELETE(ids, cbSuccess, cbFail) {
  try {
    const response = await axios.delete(`assets/upload-image?ids=${ids}`);
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}




