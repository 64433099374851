import {createContext, useContext, useState} from 'react';

const ClubRatingSystemContext = createContext();

function ProviderClubRatingsSystem({clubRatingsSystem, children}) {
  return (
      <ClubRatingSystemContext.Provider value={clubRatingsSystem}>
        {children}
      </ClubRatingSystemContext.Provider>
  );
}

export default ProviderClubRatingsSystem;

export function useClubRatingsSystem() {
  return useContext(ClubRatingSystemContext) || {};
}

