import {Box} from '@mui/system';
import {debounce, IconButton, InputAdornment, TextField} from '@mui/material';
import {Clear, Search} from '@mui/icons-material';
import {useTournamentMatchesContext} from './TournamentMatchesProvider';

function TournamentsMatchesMatchListHeader() {

  return (
      <Box
          sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
          }}
      >
        <MatchSearch/>
      </Box>
  );
}

function MatchSearch() {

  const {
    searchRef,
    searchTerm,
    setSearchTerm,
    handleClearSearch,
    matches,
  } = useTournamentMatchesContext();

  const disabled = !matches || matches?.length === 0;

  const debounceSetSearchTerm = debounce((value) => {
    setSearchTerm(value);
  }, 500);


  return (
      <TextField
          sx={{width: '280px'}}
          disabled={disabled}
          inputRef={searchRef}
          onChange={(event) => {
            debounceSetSearchTerm(event.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>
              <Search color={'action'}/>
            </InputAdornment>,
            endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <Clear/>
                  </IconButton>
                </InputAdornment>
            ),
          }}
          placeholder={'Search...'}
      />
  );
}

export default TournamentsMatchesMatchListHeader;
