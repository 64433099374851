import {Paper} from '@mui/material';

function PageCard({
                    width = '100%',
                    maxWidth = '100%',
                    children,
                    mb = 0,
                    height = 'auto',
                    sx = {},
                  }) {
  return (
      <Paper
          variant={'elevation'}
          sx={{
            borderRadius: '12px',
            width: width,
            maxWidth: maxWidth,
            mb,
            height,
            boxShadow: 8,
            position: 'relative',
            ...sx,
          }}
      >
        {children}
      </Paper>
  );
}

export default PageCard;
