import {useSelector} from 'react-redux';
import {FEATURES} from '../../constants/features';

const useHasFeatureAccess = (module) => {

  const feature = FEATURES[module];
  const policies = useSelector((state) => state.app.policies);

  if (feature && policies) {

    const featureAccessRules = policies?.club?.featureSupports;

    const featureAccessRule = featureAccessRules?.find(
        (feat) => feat?.name === feature?.alias,
    );

    return !!featureAccessRule?.status;
  }
};
export default useHasFeatureAccess;
