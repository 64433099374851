import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import PageSection from '../../_Global/Page/PageSection';
import PageSectionTitle from '../../_Global/Page/PageSectionTitle';
import {useTranslate} from '@tolgee/react';
import RHFPlainTextInput from '../../_Global/Inputs/RHFPlainTextInput';
import RHFSelectSimple from '../../_Global/Inputs/RHFSelectSimple';
import RHFNumberInput from '../../_Global/Inputs/RHFNumberInput';
import RHFSwitch from '../../_Global/Inputs/RHFSwitch';
import {FlagOutlined} from '@mui/icons-material';
import RHFDatePicker from '../../_Global/Inputs/RHFDatePicker';
import RHFTimePicker from '../../_Global/Inputs/RHFTimePicker';
import PageCardContent from '../../_Global/Page/PageCard/PageCardContent';
import PageCardActions from '../../_Global/Page/PageCard/PageCardActions';
import {useFormContext} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import RHFFileUpload from '../../_Global/Inputs/RHFFileUpload';
import useDefaults from '../../../hooks/defaults/useDefaults';
import RHFSlider from '../../_Global/Inputs/RHFSlider';
import RHFAutoComplete from '../../_Global/Inputs/RHFAutoComplete';
import {useEffect, useState} from 'react';
import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import {sportsList_GET} from '../../../vendor/redux/actions/sports';
import {useDispatch} from 'react-redux';
import RHFSelectMultiple from '../../_Global/Inputs/RHFSelectMultiple';
import {courtsList_GET} from '../../../vendor/redux/actions/courts';
import {useSnackbar} from 'notistack';
import UploadedFileList
  from '../../_Global/Uploads/UploadedFileList/UploadedFileList';
import {
  tournamentsCreate_POST,
  tournamentsOrganizers_GET,
  tournamentsUpdate_PATCH,
} from '../../../api/tournaments';
import {
  useClubRatingsSystem,
} from '../../../providers/ProviderClubRatingsSystem';
import dayjs from 'dayjs';
import {
  uploadFileListHandleDelete,
} from '../../_Global/Uploads/UploadedFileList/utils';

function TournamentsAddEditForm() {

  const {t} = useTranslate();
  const {watch} = useFormContext();

  const selectedSport = watch('sport');
  const clubRatingSystem = useClubRatingsSystem();
  const sportHasRatingSystem = !!clubRatingSystem[selectedSport?.toLowerCase()];

  return (
      <Box>
        <PageCardContent>
          <DisplayOnClubPage/>

          <PageSection>
            <PageSectionTitle
                text={t('tournaments.create.section.basicInfo')}
            />
            <Grid container spacing={2}>
              <TournamentName/>
              <Description/>
              <TournamentVisibility/>
              <TournamentFormat/>
              <ScoringFormat/>
              <TournamentCategories/>
              <ParticipantCount/>
              <ParticipantPrice/>
              <TournamentOrganizers/>
            </Grid>
          </PageSection>


          <PageSection>
            <PageSectionTitle
                text={t('tournaments.create.section.sport')}
            />
            <Grid container spacing={2}>
              <Sport/>
            </Grid>
          </PageSection>
          {
              selectedSport && sportHasRatingSystem &&
              <PageSection>
                <PageSectionTitle
                    text={t('tournaments.create.section.ratingRange')}
                    titleMeta={<ToggleRatingRange/>}
                />
                <Grid container spacing={2}>
                  <RatingRange/>
                </Grid>
              </PageSection>
          }

          {
              selectedSport &&
              <PageSection>
                <PageSectionTitle
                    text={t('tournaments.create.section.assignCourt')}
                    titleMeta={<ToggleAssignCourts/>}
                />
                <Grid container spacing={2}>
                  <Courts/>
                </Grid>
              </PageSection>
          }


          <PageSection>
            <PageSectionTitle
                text={t('tournaments.create.section.dateTime')}
            />
            <Grid container spacing={2}>
              <TournamentStartDate/>
              <TournamentEndDate/>
              <TournamentStartTime/>
              <TournamentEndTime/>
            </Grid>
          </PageSection>

          <PageSection>
            <PageSectionTitle
                text={t('tournaments.create.section.registrationDeadline')}
            />
            <Grid container spacing={2}>
              <RegistrationDeadlineDate/>
              <RegistrationDeadlineTime/>
            </Grid>
          </PageSection>

          <PageSection>
            <PageSectionTitle
                text={t('tournaments.create.section.internalNotes')}
            />
            <InternalNotes/>
          </PageSection>

          <PageSection>
            <Gallery/>
          </PageSection>

          <PageSection>
            <Attachments/>
          </PageSection>
        </PageCardContent>
        <PageCardActions>
          <FormActions/>
        </PageCardActions>
      </Box>
  );

}

function DisplayOnClubPage() {

  const {t} = useTranslate();

  return (
      <Stack direction="row" spacing={2} alignItems="center"
             justifyContent="space-between" mb={3}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FlagOutlined/>
          <Typography variant={'h6'}>
            {t('tournaments.create.displayOnClubPage')}
          </Typography>
        </Stack>
        <RHFSwitch
            name={'displayOnClubPageToggled'}
        />
      </Stack>
  );
}

function TournamentName() {

  const {t} = useTranslate();

  return (
      <Grid item xs={12}>
        <RHFPlainTextInput
            name={'tournamentName'}
            label={t('tournaments.create.tournamentName')}
            shrink={false}
            requiredRules={{
              value: true,
              message: t('tournaments.create.tournamentName.required'),
            }}
        />
      </Grid>
  );
}

function TournamentVisibility() {

  const {t} = useTranslate();
  const {fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentType']?.disabled;

  const options = [
    {
      id: 'private',
      value: 'private',
      label: t('tournaments.create.tournamentType.private'),
    },
    {
      id: 'public',
      value: 'public',
      label: t('tournaments.create.tournamentType.public'),
    },
  ];

  return (
      <Grid item xs={12}>

        <RHFSelectSimple
            disabled={disabled}
            name={'tournamentType'}
            label={t('tournaments.create.tournamentType')}
            options={options}
            requiredRules={{
              value: true,
              message: t('tournaments.create.tournamentType.required'),
            }}
        />
      </Grid>
  );
}

function Description() {

  const {t} = useTranslate();

  return (
      <Grid item xs={12}>

        <RHFPlainTextInput
            name={'description'}
            label={t('tournaments.create.description')}
            shrink={false}
            multiLine={true}
            rows={3}
        />
      </Grid>
  );
}

function TournamentFormat() {

  const {t} = useTranslate();

  const {fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentFormat']?.disabled;

  const options = [
    {
      value: 'americano',
      label: t('tournaments.create.tournamentFormat.americano'),
      disabled: false,
    },
    {
      value: 'mexicano',
      label: t('tournaments.create.tournamentFormat.mexicano'),
      disabled: true,
    },
    {
      value: 'knock_out',
      label: t('tournaments.create.tournamentFormat.knock_out'),
      disabled: true,
    },
    {
      value: 'single_elimination',
      label: t('tournaments.create.tournamentFormat.single_elimination'),
      disabled: true,
    },
  ];

  return (
      <Grid item xs={12}>
        <RHFSelectSimple
            disabled={disabled}
            name={'tournamentFormat'}
            label={t('tournaments.create.tournamentFormat')}
            options={options}
            requiredRules={{
              value: true,
              message: t('tournaments.create.tournamentFormat.required'),
            }}
            renderOptions={() => {
              return (
                  options.map((option, index) => {
                    return <MenuItem
                        disabled={option.disabled}
                        key={option.value}
                        value={option.value}
                        sx={{opacity: '1 !important'}}
                    >
                      <Stack
                          direction="row"
                          justifyContent="space-between"
                          width={'100%'}
                      >
                        <Typography
                            color={option.disabled ? 'text.disabled' : ''}>
                          {option.label}
                        </Typography>
                        {
                            option.disabled &&
                            <Chip
                                size="small"
                                variant={'soft'}
                                color={'primary'}
                                label={'Coming Soon'}
                            />
                        }
                      </Stack>

                    </MenuItem>;
                  })
              );
            }}
        />
      </Grid>
  );
}

function ScoringFormat() {

  const {t} = useTranslate();
  const {fieldRules} = useFormContext();
  const disabled = fieldRules['scoringFormat']?.disabled;

  const options = [
    {
      id: '16',
      value: '16',
      label: '16',
    },
    {
      id: '21',
      value: '21',
      label: '21',
    },
    {
      id: '24',
      value: '24',
      label: '24',
    },
    {
      id: '32',
      value: '32',
      label: '32',
    },
    // {
    //   id: 'time',
    //   value: 'time',
    //   label: t('tournaments.create.scoringFormat.time'),
    // },
    // {
    //   id: 'standard',
    //   value: 'standard',
    //   label: t('tournaments.create.scoringFormat.standard'),
    // },
    // {
    //   id: 'classic',
    //   value: 'classic',
    //   label: t('tournaments.create.scoringFormat.classic'),
    // },
  ];

  return (
      <Grid item xs={12}>
        <RHFSelectSimple
            name={'scoringFormat'}
            label={t('tournaments.create.scoringFormat')}
            disabled={disabled}
            options={options}
            requiredRules={{
              value: true,
              message: t('tournaments.create.scoringFormat.required'),
            }}
        />
      </Grid>
  );
}

function TournamentCategories() {

  const {t} = useTranslate();
  const {fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentCategories']?.disabled;

  //todo: we are supporting only americano for now
  const options = [
    {
      value: 'all_double',
      label: t('tournaments.types.categories.all_double'),
    },
    {
      value: 'mens_double',
      label: t('tournaments.types.categories.mens_double'),
    },
    {
      value: 'womens_double',
      label: t('tournaments.types.categories.womens_double'),
    },

    // {
    //   value: 'womens_single',
    //   label: t('tournaments.types.categories.womens_single'),
    // },
    // {
    //   value: 'mens_single',
    //   label: t('tournaments.types.categories.mens_single'),
    // },
    // {
    //   value: 'mix_double',
    //   label: t('tournaments.types.categories.mix_double'),
    // },
  ];

  return (
      <Grid item xs={12}>
        <RHFSelectMultiple
            disabled={disabled}
            name={'tournamentCategories'}
            label={t('tournaments.create.tournamentCategory')}
            options={options}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentCategory.required'),
              },
            }}
        />
      </Grid>
  );
}

function ParticipantCount() {

  const {t} = useTranslate();

  return (
      <Grid item xs={6}>
        <RHFNumberInput
            name={'participantCount'}
            label={
              `${t('tournaments.create.numberOfParticipants')}`
            }
            // label={t('tournaments.create.numberOfTeams')}
            options={[]}
            triggerOnChange={true}
            requiredRules={{
              value: true,
              message: t('tournaments.create.participantCount.required'),
            }}
            validateRules={{
              minValue: (value) => {
                if (value < 4) {
                  return t(
                      'tournaments.create.participantCount.validate.minValue',
                      {minValue: 4});
                }
                return true;
              },
              maxValue: (value) => {
                if (value > 24) {
                  return t(
                      'tournaments.create.participantCount.validate.maxValue',
                      {maxValue: 24});
                }
                return true;

              },
            }}
        />
      </Grid>
  );
}

function ParticipantPrice() {

  const {t} = useTranslate();
  const {defaultCurrencySymbol} = useDefaults();

  const {fieldRules} = useFormContext();
  const disabled = fieldRules['participantPrice']?.disabled;

  return (
      <Grid item xs={6}>
        <RHFNumberInput
            disabled={disabled}
            name={'participantPrice'}
            label={t('tournaments.create.pricePerParticipant')}
            // label={t('tournaments.create.pricePerTeam')}
            options={[]}
            endAdornment={defaultCurrencySymbol}
            requiredRules={{
              value: true,
              message: t('tournaments.create.participantPrice.required'),
            }}
        />
      </Grid>
  );
}

function TournamentOrganizers() {

  const {t} = useTranslate();
  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [organizers, setOrganizers] = useState();

  function fetchOrganizers() {

    tournamentsOrganizers_GET({clubId, cbSuccess, cbFail});

    function cbSuccess(res) {
      setOrganizers(res?.data?.data);
    }

    function cbFail(e) {
      setOrganizers([]);
    }
  }

  useEffect(() => {
    fetchOrganizers();
  }, [clubId]);

  return (
      <Grid item xs={12}>
        <RHFAutoComplete
            multiple={true}
            name={'tournamentOrganizers'}
            label={t('tournaments.create.tournamentOrganizer')}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentOrganizer.required'),
              },
            }}
            options={organizers}
            isLoading={!organizers}
            getOptionLabel={(option) => {
              return `${option.firstName || ''} ${option.lastName || ''}`;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
        />
      </Grid>
  );
}

function ToggleRatingRange() {
  return (
      <RHFSwitch
          name={'ratingRangeToggled'}
      />
  );
}

function RatingRange() {

  const ratingSystem = useClubRatingsSystem();

  const {watch} = useFormContext();
  const ratingRangeToggled = watch('ratingRangeToggled');
  const selectedSport = watch('sport');

  const sportRatingRange = ratingSystem[selectedSport?.toLowerCase()];

  return (
      <Grid item xs={12}>
        <Box sx={{px: 1}}>
          <RHFSlider
              name={'ratingRange'}
              min={sportRatingRange?.min}
              max={sportRatingRange?.max}
              step={0.1}
              autoGeneratedMarksStep={1}
              disabled={!ratingRangeToggled}
              valueLabelDisplay={'auto'}
              minDistance={.5}
          />
        </Box>

      </Grid>
  );
}

function ToggleAssignCourts() {

  const {setValue} = useFormContext();

  return (
      <RHFSwitch
          name={'assignCourtsToggled'}
          onChangeCallback={() => {
            setValue('courts', null);
          }}
      />
  );
}

function Sport() {

  const {t} = useTranslate();
  const dispatch = useDispatch();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const ratingSystem = useClubRatingsSystem();

  const {setValue, fieldRules} = useFormContext();

  const disabled = fieldRules['sport']?.disabled;

  const [sports, setSports] = useState();

  function fetchSports() {
    dispatch(sportsList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      const data = res?.data?.data;
      const normalized = data?.map((sport) => ({
        id: sport.id,
        value: sport.name,
        label: sport.name,
      }));
      setSports(normalized);
    }

    function cbFail(e) {
      console.log('sportsList_GET Fail', e);
    }
  }

  function handleOnChange(event) {

    //Reset Courts
    setValue('courts', null);

    //Reset Rating Range to fall within sport bounds
    const sport = event?.target?.value;
    const sportRating = ratingSystem[sport.toLowerCase()];
    if (sportRating) {
      setValue('ratingRange', [sportRating.min, sportRating.max]);
    }

  }

  useEffect(() => {
    fetchSports();
  }, [clubId]);

  return (
      <Grid item xs={12}>
        <RHFSelectSimple
            disabled={disabled}
            name={'sport'}
            label={t('tournaments.create.sport')}
            options={sports}
            requiredRules={{
              value: true,
              message: t('tournaments.create.sport.required'),
            }}
            onChangeCallback={handleOnChange}
        />
      </Grid>
  );
}

function Courts() {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {watch} = useFormContext();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const selectedSport = watch('sport');

  const assignCourtsToggled = watch('assignCourtsToggled');

  const [courts, setCourts] = useState();
  const [isLoading, setIsLoading] = useState();

  function fetchCourts() {

    setIsLoading(true);
    dispatch(courtsList_GET(clubId, '', '', selectedSport, cbSuccess, cbFail));

    function cbSuccess(res) {

      const data = res?.data?.data?.rows;

      const normalized = data?.map((court) => ({
        id: court.courtId,
        value: court.courtId,
        label: court.name,
      }));

      setCourts(normalized);
      setIsLoading(false);

    }

    function cbFail(e) {
      setCourts([]);
      setIsLoading(false);

    }

  }

  useEffect(() => {
    if (clubId && selectedSport) {
      fetchCourts();
    }
  }, [clubId, selectedSport]);

  if (!assignCourtsToggled) {
    return;
  }

  return (
      <Grid item xs={12}>
        <RHFSelectMultiple
            name={'courts'}
            label={t('tournaments.create.courts')}
            options={courts}
            isLoading={isLoading}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.courts.required'),
              },
            }}
        />
      </Grid>
  );
}

function TournamentStartDate() {

  const {t} = useTranslate();
  const {setValue, getValues, fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentStartDate']?.disabled;

  function handleOnChange(newDate) {
    // Update end Date to fall within start date
    const endDate = getValues('tournamentEndDate');
    if (endDate) {
      if (newDate.isAfter(endDate)) {
        setValue('tournamentEndDate', newDate);
      }
    }
  }

  return (
      <Grid item xs={6}>
        <RHFDatePicker
            disabled={disabled}
            onChangeCallback={handleOnChange}
            label={t('tournaments.create.tournamentStartDate')}
            name={'tournamentStartDate'}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentStartDate.required'),
              },
            }}
        />
      </Grid>
  );
}

function TournamentEndDate() {

  const {t} = useTranslate();
  const {watch, fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentEndDate']?.disabled;
  const startDate = watch('tournamentStartDate');

  return (
      <Grid item xs={6}>
        <RHFDatePicker
            disabled={disabled}
            label={t('tournaments.create.tournamentEndDate')}
            name={'tournamentEndDate'}
            minDate={startDate}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentEndDate.required'),
              },
            }}
        />
      </Grid>
  );
}

function TournamentStartTime() {

  const {t} = useTranslate();

  const {setValue, getValues, fieldRules} = useFormContext();
  const disabled = fieldRules['tournamentStartTime']?.disabled;

  function handleOnChange(newTime) {
    // Update end time to fall within start time
    const endTime = getValues('tournamentEndTime');
    if (endTime) {
      if (newTime.isAfter(endTime)) {
        setValue('tournamentEndTime', newTime.add(30, 'minutes'));
      }
    }
  }

  return (
      <Grid item xs={6}>
        <RHFTimePicker
            disabled={disabled}
            name={'tournamentStartTime'}
            label={t('tournaments.create.tournamentStartTime')}
            maxTime={dayjs().hour(23).minute(0).second(0).millisecond(0)}
            disablePast={false}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentStartTime.required'),
              },
            }}
            onChangeCallback={handleOnChange}
        />
      </Grid>
  );
}

function TournamentEndTime() {

  const {t} = useTranslate();

  const {watch, fieldRules} = useFormContext();
  const startTime = watch('tournamentStartTime');

  const disabled = fieldRules['tournamentEndTime']?.disabled;

  return (
      <Grid item xs={6}>
        <RHFTimePicker
            minTime={startTime?.add(30, 'minutes')}
            name={'tournamentEndTime'}
            label={t('tournaments.create.tournamentEndTime')}
            disablePast={false}
            disabled={disabled}
            rules={{
              required: {
                value: true,
                message: t('tournaments.create.tournamentEndTime.required'),
              },
            }}
        />
      </Grid>
  );
}

function RegistrationDeadlineDate() {
  const {t} = useTranslate();

  return (
      <Grid item xs={12}>
        <RHFDatePicker
            label={t('tournaments.create.registrationDeadlineDate')}
            name={'registrationDeadlineDate'}
            rules={{
              required: {
                value: true,
                message: t(
                    'tournaments.create.registrationDeadlineDate.required'),
              },
            }}
        />
      </Grid>
  );
}

function RegistrationDeadlineTime() {
  const {t} = useTranslate();

  return (
      <Grid item xs={12}>
        <RHFTimePicker
            name={'registrationDeadlineTime'}
            label={t('tournaments.create.registrationDeadlineTime')}
            rules={{
              required: {
                value: true,
                message: t(
                    'tournaments.create.registrationDeadlineTime.required'),
              },
            }}
        />
      </Grid>
  );
}

function InternalNotes() {
  const {t} = useTranslate();
  return (
      <RHFPlainTextInput
          name={'internalNotes'}
          label={t('tournaments.create.internalNotes')}
          multiLine={true}
          rows={2}
      />
  );
}

function Gallery() {

  const {t} = useTranslate();
  const {watch, setValue, getValues} = useFormContext();
  const galleryImages = watch('galleryImages');

  return (
      <Box>
        <PageSectionTitle
            text={t('tournaments.create.gallery')}
            titleMeta={
              <RHFFileUpload
                  name={'galleryImages'}
                  accept={'img'}
                  render={({isLoading}) => {
                    return (
                        <Button
                            disabled={isLoading}
                            color={'primary'}
                            variant={'text'}
                            startIcon={
                                isLoading &&
                                <CircularProgress size={20}/>
                            }
                        >
                          {t('tournaments.create.gallery.uploadImage')}
                        </Button>
                    );
                  }}
              />
            }
        />

        <UploadedFileList
            files={galleryImages}
            type={'preview'}
            emptyText={'No Images'}
            onDelete={(file, setIsDeleting) => {
              uploadFileListHandleDelete(file, setIsDeleting, 'galleryImages',
                  getValues, setValue);
            }}
        />

      </Box>
  );
}

function Attachments() {

  const {t} = useTranslate();
  const {watch, getValues, setValue} = useFormContext();
  const attachments = watch('attachments');

  return (
      <Box>
        <PageSectionTitle
            text={t('tournaments.create.attachments')}
            titleMeta={
              <RHFFileUpload
                  name={'attachments'}
                  accept={'pdf'}
                  render={({isLoading}) => {
                    return (
                        <Button
                            variant={'text'}
                            disabled={isLoading}
                            color={'primary'}
                            startIcon={
                                isLoading &&
                                <CircularProgress size={20}/>
                            }
                        >
                          {t('tournaments.create.gallery.uploadPdf')}
                        </Button>
                    );
                  }}
              />
            }
        />

        <UploadedFileList
            emptyText={'No attachments'}
            files={attachments}
            type={'list'}
            onDelete={(file, setIsDeleting) => {
              uploadFileListHandleDelete(file, setIsDeleting, 'attachments',
                  getValues, setValue);
            }}
        />

      </Box>
  );
}

function FormActions() {

  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {tournamentId} = useParams();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const {
    handleSubmit,
    formState: {isDirty, isSubmitting},
    setFocus,
    mode,
    status,
  } = useFormContext();

  const isActiveStatus = status === 'active';

  function handleCancelClick() {
    if (mode === 'add') {
      navigateTo('/tournaments');
    }
    if (mode === 'edit') {
      navigateTo(`/tournaments/detail/${tournamentId}`);
    }
  }

  function handleSaveClick() {
    return handleSubmit(handleSubmitOnValid, handleSubmitOnInvalid)();
  }

  function handleSubmitOnValid(data) {

    const {
      displayOnClubPageToggled,
      tournamentName,
      tournamentType,
      description,
      tournamentFormat,
      scoringFormat,
      tournamentCategories,
      participantCount,
      participantPrice,
      tournamentOrganizers,
      ratingRangeToggled,
      ratingRange,
      assignCourtsToggled,
      sport,
      courts,
      tournamentStartDate,
      tournamentEndDate,
      tournamentStartTime,
      tournamentEndTime,
      registrationDeadlineDate,
      registrationDeadlineTime,
      internalNotes,
      galleryImages,
      attachments,
    } = data;

    const imagesToAdd = galleryImages?.filter((i) => !i.markedForDelete).
        map((i) => i.imageId) || [];

    const pdfsToAdd = attachments?.filter((i) => !i.markedForDelete).
        map((i) => i.imageId) || [];

    const imagesMarkedForDelete = galleryImages?.filter(
        (i) => i.markedForDelete)?.map((i) => i.imageId) || [];

    const pdfsMarkedForDelete = attachments?.filter(
        (i) => i.markedForDelete)?.map((i) => i.imageId) || [];

    const body = {
      'tournament': {
        ...(!isActiveStatus && {
          'type': tournamentType,
          'sport': sport,
          'format': tournamentFormat,
          'scoringFormat': scoringFormat,
          'startDate': tournamentStartDate.format('YYYY-MM-DD'),
          'endDate': tournamentEndDate.format('YYYY-MM-DD'),
          'startTime': tournamentStartTime.format('HH:mm:ss'),
          'endTime': tournamentEndTime.format('HH:mm:ss'),
        }),
        'name': tournamentName,
        'description': description,
        'registrationDeadlineDate': registrationDeadlineDate.format(
            'YYYY-MM-DD'),
        'registrationDeadlineTime': registrationDeadlineTime.format('HH:mm:ss'),
        'enablePlayerSide': displayOnClubPageToggled,
        'internalNotes': internalNotes,
        'clubId': clubId,
        'organizerIds': tournamentOrganizers?.map((o) => o.id),
        'galleryImageIds': imagesToAdd,
        'attachmentImageIds': pdfsToAdd,
        ...(mode === 'edit' && {
          'imageDeletionIds': [
            ...imagesMarkedForDelete,
            ...pdfsMarkedForDelete,
          ],
        }),
      },

      'categories': tournamentCategories?.map((type) => {
        return {
          'type': type,
          'price': participantPrice,
          'participantCount': participantCount,
          'minRating': ratingRangeToggled ? ratingRange[0] : null,
          'maxRating': ratingRangeToggled ? ratingRange[1] : null,
        };
      }),
      'courts': assignCourtsToggled ? courts : '',

    };

    if (mode === 'add') {
      return tournamentsCreate_POST({body, cbSuccess, cbFail});
    }

    if (mode === 'edit') {
      return tournamentsUpdate_PATCH({
        id: tournamentId, body, cbSuccess, cbFail,
      });
    }

    function cbSuccess(res) {

      const response = res?.data?.data;
      const tournamentId = response?.id;
      navigateTo(`/tournaments/detail/${tournamentId}`);

      if (mode === 'edit') {
        enqueueSnackbar('Tournament updated', {
          variant: 'success',
        });
      }

      if (mode === 'add') {
        enqueueSnackbar('Tournament created', {
          variant: 'success',
        });
      }

    }

    function cbFail(e) {
      enqueueSnackbar('Tournament create failed', {
        variant: 'error',
      });
    }

  }

  function handleSubmitOnInvalid(errors) {
    enqueueSnackbar(t('global.validation.fail'), {
      variant: 'error',
    });
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      setFocus(firstErrorField);
    }
  }

  return (
      <Stack direction={'row'} alignItems={'center'}
             justifyContent={'flex-end'} width={'100%'} spacing={1}>
        <Button
            variant={'outlined'}
            onClick={handleCancelClick}
        >
          {t('global.buttons.actions.cancel')}
        </Button>
        <LoadingButton
            variant={'contained'}
            onClick={handleSaveClick}
            loading={isSubmitting}
            disabled={mode === 'edit' && !isDirty}
        >
          <span>{t('global.buttons.actions.save')}</span>
        </LoadingButton>
      </Stack>
  );

}

export default TournamentsAddEditForm;
