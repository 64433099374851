function PlumpyEvents(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M15,19c0-2.209,1.791-4,4-4c0.732,0,1.41,0.211,2,0.555V6H3v12c0,1.657,1.343,3,3,3h9.555 C15.211,20.41,15,19.732,15,19z"
              opacity=".35" fill="currentColor"/>
          <path d="M18,3H6C4.343,3,3,4.343,3,6v1h18V6C21,4.343,19.657,3,18,3z"
                fill="currentColor"/>
          <path fillRule="evenodd"
                d="M14.866,14.753l1.645-1.7c0.17-0.176,0.229-0.431,0.154-0.664 c-0.076-0.233-0.274-0.405-0.515-0.447l-2.33-0.408c-0.027-0.005-0.053-0.016-0.075-0.032c-0.022-0.016-0.04-0.037-0.053-0.061 l-1.108-2.09C12.47,9.135,12.245,9,12,9s-0.47,0.135-0.584,0.351l-1.108,2.09c-0.013,0.024-0.031,0.045-0.053,0.061 c-0.022,0.016-0.048,0.027-0.075,0.032l-2.33,0.408c-0.241,0.042-0.439,0.214-0.515,0.447c-0.076,0.233-0.017,0.488,0.154,0.664 l1.645,1.7c0.019,0.02,0.033,0.043,0.042,0.07c0.009,0.026,0.011,0.054,0.007,0.081l-0.332,2.342 c-0.034,0.242,0.068,0.484,0.266,0.628c0.198,0.144,0.459,0.166,0.679,0.059l2.125-1.039c0.025-0.012,0.052-0.018,0.079-0.018 s0.054,0.006,0.079,0.018l2.125,1.039c0.22,0.108,0.481,0.085,0.679-0.059c0.198-0.144,0.3-0.385,0.266-0.628l-0.332-2.342 c-0.004-0.027-0.002-0.055,0.007-0.081C14.833,14.797,14.847,14.773,14.866,14.753z"
                clipRule="evenodd" fill="currentColor"/>
          <circle cx="19" cy="19" r="4" opacity=".35" fill="currentColor"/>
          <path
              d="M19,14c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S21.761,14,19,14z M21.619,18.619l-2.5,2.5 c-0.165,0.165-0.385,0.256-0.619,0.256s-0.454-0.091-0.619-0.256l-1.5-1.5c-0.341-0.342-0.341-0.896,0-1.238 c0.341-0.34,0.896-0.34,1.237,0l0.881,0.882l1.881-1.882c0.341-0.34,0.896-0.34,1.237,0C21.96,17.723,21.96,18.277,21.619,18.619z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyEvents;
