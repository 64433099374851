import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Stack,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import DialogSection from '../../_Global/Dialogs/DialogSection';
import {
  AccountBalanceWalletOutlined,
  Add,
  CategoryOutlined,
  PeopleAltOutlined,
  RemoveCircleOutlineOutlined,
} from '@mui/icons-material';
import RHFSelectSimple from '../../_Global/Inputs/RHFSelectSimple';
import RHFRadioGroup from '../../_Global/Inputs/RHFRadioGroup';
import useDefaults from '../../../hooks/defaults/useDefaults';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import RHFPlayerSearch from '../../_Global/Inputs/RHFPlayerSearch';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import {tournamentsRegistrationsAdd_POST} from '../../../api/tournaments';
import {useParams} from 'react-router-dom';

function RegistrationsAddParticipantDialog({
                                             open,
                                             onClose = () => {
                                             },
                                             tournamentDetails,
                                             onAdd,
                                             registeredUserIds,
                                           }) {

  const {t} = useTranslate();

  const tournamentCategories = tournamentDetails?.tournamentCategories;

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogFormProvider
            categories={tournamentCategories}
        >
          <DialogTitle>
            {t('tournaments.detail.registrations.addParticipant.dialog.title')}
          </DialogTitle>
          <DialogContent>
            <CategorySelect
                categories={tournamentCategories}
            />
            <RegistrationFeeType
                categories={tournamentCategories}
            />
            <TeamSelect
                categories={tournamentCategories}
                registeredUserIds={registeredUserIds}
            />
          </DialogContent>
          <DialogActions>
            <FormActions
                onClose={onClose}
                categories={tournamentCategories}
                onAdd={onAdd}
            />
          </DialogActions>
        </DialogFormProvider>
      </Dialog>

  );
}

function DialogFormProvider({categories, children}) {

  const initialCategory = categories[0]?.id;

  const formProps = useForm({
    defaultValues: {
      category: initialCategory,
      feeType: 'invite',
      teams: [
        {
          participants: [],
        },
      ],
    },
  });

  return (
      <FormProvider {...formProps}>
        {children}
      </FormProvider>
  );
}

function CategorySelect({categories}) {

  const {t} = useTranslate();
  const {setValue, clearErrors} = useFormContext();

  const options = categories?.map(category => {
    return {
      value: category.id,
      label: t(`tournaments.types.categories.${category.type}`),
    };
  });

  return (
      <DialogSection
          icon={CategoryOutlined}
          label={t('tournaments.detail.registrations.addParticipant.category')}
      >
        <RHFSelectSimple
            name={'category'}
            label={t(
                'tournaments.detail.registrations.addParticipant.selectCategory')}
            options={options}
            requiredRules={{
              value: true,
              message: t(
                  'tournaments.detail.registrations.addParticipant.selectCategory.required'),
            }}
            onChangeCallback={() => {
              const resetValue = [{participants: []}];
              setValue('teams', resetValue);
              clearErrors();
            }}

        />
      </DialogSection>
  );
}

function RegistrationFeeType({categories}) {

  const {t} = useTranslate();
  const {watch} = useFormContext();
  const {defaultCurrencySymbol} = useDefaults();
  const {locale} = useLocale();

  const selectedCategoryId = watch('category');

  const price = (() => {
    let p = 0;
    if (selectedCategoryId) {
      const c = categories?.find((c) => c.id === selectedCategoryId);
      p = +c.price;
    }
    return p;
  })();

  const options = [
    {
      value: 'invite',
      label: `${getLocalizedNum(locale, price)}${defaultCurrencySymbol}`,
    },
    {
      value: 'free',
      label: t('tournaments.registrations.fee.free'),
    },
  ];

  return (
      <DialogSection
          icon={AccountBalanceWalletOutlined}
          label={t('tournaments.detail.registrations.addParticipant.fee')}
          isDisabled={!selectedCategoryId}
      >
        <RHFRadioGroup
            name={'feeType'}
            options={options}
            disabled={!selectedCategoryId}
        />
      </DialogSection>
  );
}

function TeamSelect({categories, registeredUserIds}) {

  const {t} = useTranslate();
  const {watch, formState: {errors}} = useFormContext();

  const selectedCategoryId = watch('category');
  const teams = watch('teams');

  const selectedParticipantIds = teams?.flatMap(
      t => t?.participants?.map(p => p.userId));

  const filteredUserIds = [
    ...(selectedParticipantIds || []),
    ...(registeredUserIds || []),
  ];

  const selectedCategory = categories.find((c) => c.id === selectedCategoryId);

  const sectionLabel = (() => {

    let singlesLabel = t(
        'tournaments.detail.registrations.addParticipant.participants');

    let doublesLabel = t(
        'tournaments.detail.registrations.addParticipant.teams');

    let l = singlesLabel;

    if (selectedCategory) {
      if (selectedCategory.registrationType === 'double') {
        l = doublesLabel;
      }
    }

    return l;

  })();

  const maxParticipantsPerTeam = (() => {

    let mp;

    if (selectedCategory) {
      if (selectedCategory.registrationType === 'double') {
        mp = 2;
      }
    }

    return mp;
  })();

  const allowMultipleTeams = maxParticipantsPerTeam;

  const {fields, append, remove} = useFieldArray({
    name: 'teams',
    rules: {
      required: {
        value: true,
        message: t('tournaments.registrations.teams.required'),
      },
    },
  });

  const validateRules = (() => {

    if (allowMultipleTeams) {
      return {
        required: {
          value: true,
          message: t('tournaments.addParticipants.min2Required'),
        },
        validate: {
          min2Participants: (value) => {
            if (value?.length >= 2) {
              return true;
            } else {
              return t('tournaments.addParticipants.min2Required');
            }
          },
        },
      };
    } else {
      return {
        required: {
          value: true,
          message: t('tournaments.addParticipants.required'),
        },
        validate: {},
      };
    }

  })();

  const noTeamsError = errors?.teams?.root?.message;

  return (
      <DialogSection
          icon={PeopleAltOutlined}
          label={sectionLabel}
          isLastChild={true}
      >
        <Stack rowGap={2} mb={1}>
          {
            fields?.map((field, i) => {
              return (
                  <Stack
                      direction="row"
                      alignItems={'flex-start'}
                      key={field.id}
                      columnGap={1}
                  >
                    <RHFPlayerSearch
                        name={`teams.${i}.participants`}
                        placeholder={t(
                            'tournaments.addParticipants.placeholder')}
                        maxParticipants={maxParticipantsPerTeam}
                        filterUserIds={filteredUserIds}
                        rules={validateRules}
                    />
                    {
                        allowMultipleTeams &&
                        <IconButton
                            size={'small'}
                            onClick={() => {
                              remove(i);
                            }}
                            sx={{
                              mt: 1.25,
                            }}
                        >
                          <RemoveCircleOutlineOutlined/>
                        </IconButton>
                    }
                  </Stack>

              );
            })
          }
        </Stack>
        {
            noTeamsError &&
            <FormHelperText error={true}>
              {noTeamsError}
            </FormHelperText>
        }
        {
            allowMultipleTeams &&
            <Stack direction={'row'} justifyContent={'center'}>
              <Button
                  size={'small'}
                  startIcon={<Add/>}
                  onClick={() => {
                    append({participants: []});
                  }}
              >
                Team
              </Button>
            </Stack>
        }
      </DialogSection>
  );
}

function FormActions({categories, onClose, onAdd}) {

  const {enqueueSnackbar} = useSnackbar();
  const {tournamentId} = useParams();
  const {t} = useTranslate();

  const {
    watch,
    handleSubmit,
    setFocus,
    formState: {isSubmitting},
  } = useFormContext();

  const category = watch('category');
  const selectedCategory = categories.find((c) => c.id === category);
  const registrationType = selectedCategory?.registrationType;

  const submitLabel = (() => {
    let l = t('tournaments.registrations.add.addParticipants');
    if (registrationType === 'double') {
      l = t('tournaments.registrations.add.addTeams');
    }
    return l;
  })();

  function handleSubmitForm() {
    return handleSubmit(handleSubmitOnValid, handleSubmitOnInvalid)();
  }

  function handleSubmitOnValid(data) {

    const body = {
      'categoryId': data.category,
      'type': data.feeType,
      'sendEmail': true,
    };

    if (registrationType === 'double') {
      body.teams = data?.teams?.map((t) => {
        return {
          'playerA': t.participants[0].email,
          'playerB': t.participants[1].email,
        };
      });
    }

    if (registrationType === 'single') {
      body.players = data?.teams[0]?.participants?.map((p) => {
        return p.email;
      });
    }

    return tournamentsRegistrationsAdd_POST({
      tournamentId,
      body,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      onAdd();
      onClose();
      enqueueSnackbar(
          t('tournaments.registrations.addParticipants.success'),
          {variant: 'success'},
      );
    }

    function cbFail(e) {
      console.log('add Fail', e);
      enqueueSnackbar(
          t('tournaments.registrations.addParticipants.fail'),
          {variant: 'error'},
      );
    }

  }

  function handleSubmitOnInvalid(errors) {
    enqueueSnackbar(t('global.validation.fail'), {
      variant: 'error',
    });
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      setFocus(firstErrorField);
    }
  }

  return (
      <Stack direction={'row'} spacing={1}>
        <Button
            variant="outlined"
            onClick={onClose}
        >
          {t('global.buttons.actions.close')}
        </Button>
        <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isSubmitting}
        >
          <span>{submitLabel}</span>
        </LoadingButton>
      </Stack>
  );
}

export default RegistrationsAddParticipantDialog;
