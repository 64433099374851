import {Navigate, Outlet} from 'react-router-dom';
import {getHasCapabilities, getHasCapability} from '../utils/access';
import useUser from '../hooks/access/useUser';
import { useSelector } from 'react-redux';
import useHasFeatureAccess from '../hooks/access/useHasFeatureAccess';
import {FEATURES} from '../constants/features';

function AccessProtected({capabilities, module, redirectPath = '/', isIntegrations = false,checkForAccess=true}) {

  const feature = FEATURES[module];
  const isAccessible = useHasFeatureAccess(module);
  const user = useUser();
  const userPermissions = user?.userObj?.adminRole?.permissions;
  const selectedClubIntegrationList = useSelector(state => state.app.clubsIntegrations);
  const isItegrationCheck = isIntegrations ? selectedClubIntegrationList?.length > 0 : true
  let hasAccess;

  if (!capabilities || !module) {
    throw new Error('Required params: capabilities, module');
  }

  if (typeof capabilities === 'string') {
    hasAccess = getHasCapability(userPermissions, capabilities, module);
  }

  if (Array.isArray(capabilities)) {
    hasAccess = getHasCapabilities(userPermissions, capabilities, module);
  }

  if(feature && !!checkForAccess){
    hasAccess = isAccessible;
  }

  if (hasAccess && isItegrationCheck) {
    return <Outlet/>;
  } else {
    return <Navigate to={redirectPath}/>;
  }
}

export default AccessProtected;


