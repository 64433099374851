import axios from 'axios';

export async function tournamentsOrganizers_GET({clubId, cbSuccess, cbFail}) {
  try {
    const url = `tournaments/organizer-list/${clubId}`;
    const response = await axios.get(url);
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsSingle_GET({tournamentId, cbSuccess, cbFail}) {
  try {
    const url = `tournaments/single/${tournamentId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsBookings_GET({
                                                tournamentId,
                                                cbSuccess,
                                                cbFail,
                                              }) {
  try {
    const url = `tournaments/booking/${tournamentId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsList_GET({clubId, type, cbSuccess, cbFail}) {
  try {
    const url = `tournaments/list?clubId=${clubId}&type=${type}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsCreate_POST({body, cbSuccess, cbFail}) {
  try {
    const response = await axios.post('tournaments/create', body);
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsUpdate_PATCH({id, body, cbSuccess, cbFail}) {
  try {
    const response = await axios.patch(`tournaments/update/${id}`, body);
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsStatusUpdate_PATCH({
                                                      tournamentId,
                                                      status,
                                                      cbSuccess,
                                                      cbFail,
                                                    }) {
  try {
    const response = await axios.patch(
        `tournaments/update/${tournamentId}/status`, {
          status,
        });
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsRegistrationsAdd_POST({
                                                         tournamentId,
                                                         body,
                                                         cbSuccess,
                                                         cbFail,
                                                       }) {
  try {
    const response = await axios.post(`/tournaments/add-player/${tournamentId}`,
        body);
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsRegisteredUsersList_GET({
                                                           tournamentId,
                                                           type,
                                                           cbSuccess,
                                                           cbFail,
                                                         }) {
  try {
    const url = `/tournaments/registered-users/${tournamentId}?type=${type}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsInvitedUsersList_GET({
                                                        tournamentId,
                                                        cbSuccess,
                                                        cbFail,
                                                      }) {
  try {
    const url = `/tournaments/invited-users/${tournamentId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsRegistrationsParticipantRemove_POST({
                                                                       participantId,
                                                                       cbSuccess,
                                                                       cbFail,
                                                                     }) {
  try {
    const response = await axios.post(
        `/tournaments/remove-player/${participantId}`, {
          sendEmail: true,
          refund: true,
          refundChannel: 'bank',
        });
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsRoundsList_GET({
                                                  tournamentId,
                                                  categoryId,
                                                  cbSuccess,
                                                  cbFail,
                                                }) {
  try {
    const url = `/tournaments/rounds-list/${tournamentId}?categoryId=${categoryId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsMatchesList_GET({
                                                   tournamentId,
                                                   categoryId,
                                                   roundId,
                                                   cbSuccess,
                                                   cbFail,
                                                 }) {
  try {
    const url = `/tournaments/matches-list/${tournamentId}?categoryId=${categoryId}&roundId=${roundId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsMatchScoreUpdate_PATCH({
                                                          matchId,
                                                          teamAScore,
                                                          teamBScore,
                                                          cbSuccess,
                                                          cbFail,
                                                        }) {
  try {
    const response = await axios.patch(
        `tournaments/match/${matchId}/score`, {
          teamAScore,
          teamBScore,
        });
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsScoreboardList_GET({
                                                      tournamentId,
                                                      categoryId,
                                                      cbSuccess,
                                                      cbFail,
                                                    }) {
  try {
    const url = `/tournaments/scoreboard-list/${tournamentId}?categoryId=${categoryId}`;
    const response = await axios.get(url);
    cbSuccess(response?.data?.data);
  } catch (e) {
    cbFail(e);
  }
}

export async function tournamentsStartMatches_POST({
                                                     tournamentId,
                                                     categoryId,
                                                     cbSuccess,
                                                     cbFail,
                                                   }) {
  try {
    const url = `/tournaments/start/${tournamentId}`;
    const response = await axios.post(url, {
      categoryId,
    });
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
  }
}


