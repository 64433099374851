import {useTolgee, useTranslate} from '@tolgee/react';
import {
  Box,
  ButtonBase,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import FlagEnUS from '../../../assets/flags/FlagEnUS';
import FlagNl from '../../../assets/flags/FlagNl';
import FlagDE from '../../../assets/flags/FlagDE';
import {useState} from 'react';
import useUser from '../../../hooks/access/useUser';
import {useSnackbar} from 'notistack';
import {saveLanguageForUserHandler} from '../../../utils/settings';
import {useDispatch} from 'react-redux';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import FlagES from '../../../assets/flags/FlagES';
import FlagTr from '../../../assets/flags/FlagTR';

function SiteLanguageSwitcher({
                                onSuccess,
                                variant = 'compact' || 'full',
                                menuAnchorOrigin = {
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                },
                                menuTransformOrigin = {
                                  horizontal: 'right',
                                },
                              }) {

  const tolgee = useTolgee(['language']);
  const {locale, setLocale} = useLocale();
  const {enqueueSnackbar} = useSnackbar();
  const user = useUser();
  const dispatch = useDispatch();
  const {t} = useTranslate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const open = Boolean(anchorEl);

  const editLanguageHandler = (lang) => {

    dispatch(
        saveLanguageForUserHandler(user?.userObj?.id, lang, cbSuccess, cbFail,
            setUpdateLoading));

    function cbSuccess(res) {

      // enqueueSnackbar(t('settings.language.alert.update'), {variant: 'success'})
      setUpdateLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    }

    function cbFail(e) {

      const error = e?.response?.data?.data
      console.log('Err on language change:',e);
      enqueueSnackbar(error || t('networkError'), {variant: 'error'})
      setUpdateLoading(false);

    }

  };

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleClickMenuItem(key) {
    tolgee.changeLanguage(key);
    localStorage.setItem('locale', JSON.stringify(key));
    setLocale(key);
    handleCloseMenu();
    if (user) {
      editLanguageHandler(key);
    }
  }

  const flags = {
    en: FlagEnUS,
    nl: FlagNl,
    de: FlagDE,
    es: FlagES,
    tr: FlagTr,
  };

  let SelectedFlag = flags[locale];

  const locales = [
    {
      key: 'en',
      label: 'English (US)',
    },
    {
      key: 'nl',
      label: 'Nederlands (NL)',
    },
    {
      key: 'de',
      label: 'German (DE)',
    },
    {
      key: 'es',
      label: 'Spanish (ES)',
    },
    {
      key: 'tr',
      label: 'Turkish (TR)',
    },
  ];

  const selectedLocale = locales.find((l) => l.key === locale);

  return (
      <Box>
        {
            variant === 'full' &&
            <ButtonBase
                onClick={handleClickOpenMenu}
                sx={{
                  backgroundColor: '#fff',
                  py: 1,
                  px: 2,
                  borderRadius: '38px',
                }}
            >
              <Stack
                  direction="row"
                  alignItems={'center'}
              >
                <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: 1,
                    }}
                >
                  <SelectedFlag/>
                </Box>
                <Typography variant={'body2'} color={'text.primary'} mr={.5}>
                  {selectedLocale?.label}
                </Typography>
                {
                  open ?
                      <ExpandLess color={'action'}/> :
                      <ExpandMore color={'action'}/>
                }
              </Stack>
            </ButtonBase>
        }
        {
            variant === 'compact' &&
            <IconButton onClick={handleClickOpenMenu} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              svg: {
                width: '26px',
                height: '26px',
              },
            }}>
              <SelectedFlag/>
            </IconButton>
        }
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={menuAnchorOrigin}
            transformOrigin={menuTransformOrigin}
        >
          {
            locales.map((item) => {
              return <ListItem item={item}
                               flags={flags}
                               onClick={handleClickMenuItem}/>;
            })
          }
        </Menu>

      </Box>
  );
}

function ListItem({item, flags, onClick}) {
  const Flag = flags[item.key];
  return (
      <MenuItem
          value={item.key}
          onClick={() => {
            onClick(item.key);
          }}
      >
        <ListItemIcon sx={{
          mr: .5,
          svg: {
            width: '28px',
            height: '28px',
          },
        }}><Flag/></ListItemIcon>
        {item.label}
      </MenuItem>
  );
}

export default SiteLanguageSwitcher;


