import {alpha, Box, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {FolderOffOutlined} from '@mui/icons-material';

function EmptyPlaceholder({title, icon, actionSlot, showIcon = true, height}) {

  const {t} = useTranslate();
  const theme = useTheme();

  const defaultTitle = t('emptyPlaceholder.defaultTitle');
  const defaultIcon = <FolderOffOutlined color={'action'}/>;

  return (
      <Box
      sx={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height,
      }}
      >
        <Stack spacing={2} >
          <Stack alignItems={'center'} spacing={1}>
            {
                showIcon &&
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: alpha(theme.palette.primary.light, .15),
                      borderRadius: '50%',
                      p: '12px',
                    }}
                >
                  {icon || defaultIcon}
                </Box>

            }
            <Typography>
              {title || defaultTitle}
            </Typography>
          </Stack>

          <Stack direction={'row'} justifyContent={'center'}>
            {actionSlot}
          </Stack>
        </Stack>
      </Box>

  );
}

export default EmptyPlaceholder;
