import TournamentsList
  from '../../../components/Tournaments/List/TournamentsList';

function PageTournamentsListActive() {
  return (
      <TournamentsList type={'active'}/>
  );
}

export default PageTournamentsListActive;
