import {Chip, Stack} from '@mui/material';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import RegistrationsSectionRegistered
  from '../../../components/Tournaments/Registrations/RegistrationsSectionRegistered';
import RegistrationsSectionCancelled
  from '../../../components/Tournaments/Registrations/RegistrationsSectionCancelled';
import RegistrationsSectionInvited
  from '../../../components/Tournaments/Registrations/RegistrationsSectionInvited';
import {useTranslate} from '@tolgee/react';
import {useEffect, useMemo, useState} from 'react';
import {
  tournamentsInvitedUsersList_GET,
  tournamentsRegisteredUsersList_GET,
} from '../../../api/tournaments';
import {useParams} from 'react-router-dom';

function PageTournamentsDetailRegistrations({tournamentDetails}) {

  const {t} = useTranslate();
  const {tournamentId} = useParams();

  const [registered, setRegistered] = useState();
  const [loadingRegistered, setLoadingRegistered] = useState(true);

  const [cancelled, setCancelled] = useState();
  const [loadingCancelled, setLoadingCancelled] = useState(true);

  const [invited, setInvited] = useState();
  const [loadingInvited, setLoadingInvited] = useState(true);

  const sharedColumns = [
    {
      ...columnTypeDefaults,
      field: 'name',
      headerName: t('tournaments.registrations.table.column.name'),
      valueGetter: (_, row) => {
        const user = row?.user;
        return `${user?.firstName} ${user?.lastName}`;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'email',
      headerName: t('tournaments.registrations.table.column.email'),
      valueGetter: (_, row) => {
        const user = row?.user;
        return user?.email;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'phone',
      headerName: t('tournaments.registrations.table.column.phone'),
      valueGetter: (_, row) => {
        const user = row?.user;
        return user?.cellNumber;
      },
    },
    {
      ...columnTypeDefaults,
      flex: .3,
      field: 'status',
      headerName: t('tournaments.registrations.table.column.status'),
      valueGetter: (_, row) => {
        return row?.paymentStatus;
      },
      renderCell: ({row}) => {
        return <UserRegistrationStatusChip status={row?.paymentStatus}/>;
      },
    },
  ];

  const registeredUserIds = useMemo(() => {

    const invitedIds = (invited || [])?.map((i) => i.userId);
    const registeredIds = (registered || [])?.map((r) => r.user?.id);

    return [...registeredIds, ...invitedIds];

  }, [registered, invited]);

  function fetchRegistered() {

    setLoadingRegistered(true);

    tournamentsRegisteredUsersList_GET({
      tournamentId,
      type: 'active',
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setRegistered(res.rows);
      setLoadingRegistered(false);
    }

    function cbFail(e) {
      setLoadingRegistered(false);

    }
  }

  function fetchCancelled() {

    setLoadingCancelled(true);

    tournamentsRegisteredUsersList_GET({
      tournamentId,
      type: 'cancelled',
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setCancelled(res.rows);
      setLoadingCancelled(false);
    }

    function cbFail(e) {
      setLoadingCancelled(false);

    }
  }

  function fetchInvited() {

    setLoadingInvited(true);

    tournamentsInvitedUsersList_GET({
      tournamentId,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setInvited(res.rows);
      setLoadingInvited(false);
    }

    function cbFail(e) {
      setLoadingInvited(false);
    }
  }

  useEffect(() => {
    fetchRegistered();
    fetchCancelled();
    fetchInvited();
  }, []);

  return (
      <Stack>
        <RegistrationsSectionRegistered
            sharedColumns={sharedColumns}
            tournamentDetails={tournamentDetails}
            rows={registered}
            loading={loadingRegistered}
            registeredUserIds={registeredUserIds}
            onAdd={() => {
              fetchRegistered();
              fetchCancelled();
              fetchInvited();
            }}
        />
        <RegistrationsSectionCancelled
            sharedColumns={sharedColumns}
            rows={cancelled}
            loading={loadingCancelled}
        />
        <RegistrationsSectionInvited
            rows={invited}
            loading={loadingInvited}
        />
      </Stack>
  );
}

function UserRegistrationStatusChip({status}) {

  const {t} = useTranslate();

  let color = 'default';

  switch (status) {
    case 'unpaid':
      color = 'success';
      break;
    case 'paid':
      color = 'success';
      break;
    case 'refunded':
      color = 'default';
      break;
    case 'refund-initiated':
      color = 'default';
      break;
  }

  return (
      <Chip
          label={t(`global.types.status.${status}`)}
          color={color}
      />
  );
}

export default PageTournamentsDetailRegistrations;
