import RegistrationsSection from './RegistrationsSection';
import {useTranslate} from '@tolgee/react';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import {useState} from 'react';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import {Add, MoreVert, PersonRemove} from '@mui/icons-material';
import PageCard from '../../_Global/Page/PageCard/PageCard';
import RegistrationsAddParticipantDialog
  from './RegistrationsAddParticipantDialog';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {
  tournamentsRegistrationsParticipantRemove_POST,
} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';

function RegistrationsSectionRegistered({
                                          sharedColumns,
                                          tournamentDetails,
                                          rows,
                                          loading,
                                          onAdd = () => {
                                          },
                                          registeredUserIds,
                                        }) {

  const {t} = useTranslate();

  const [showAddParticipant, setShowAddParticipant] = useState(false);

  const sectionLabel = t('tournaments.registrations.section.registered');

  const columns = [
    ...sharedColumns,
    {
      ...columnTypeDefaults,
      filterable: false,
      sortable: false,
      field: 'action',
      flex: 0,
      headerName: '',
      renderCell: ({row}) => {
        return (
            <Stack
                direction="row"
                justifyContent={'flex-end'}
                alignItems={'center'}
                px={1}
                sx={{height: '100%'}}
            >
              <RowActions
                  row={row}
                  onRemove={onAdd}
              />
            </Stack>
        );
      },
    },
  ];

  return (
      <>
        <RegistrationsSection
            label={sectionLabel}
            count={rows?.length}
            action={
              <Button
                  onClick={() => {
                    setShowAddParticipant(true);
                  }}
                  startIcon={<Add/>}
              >
                {t('tournaments.registrations.action.addParticipants')}
              </Button>
            }
        >
          <PageCard>
            <DataGridReusable
                isLoading={loading}
                columns={columns}
                rows={rows}
                getRowId={(row) => row.id}
            />
          </PageCard>
        </RegistrationsSection>
        <RegistrationsAddParticipantDialog
            open={showAddParticipant}
            onClose={() => setShowAddParticipant(false)}
            tournamentDetails={tournamentDetails}
            onAdd={onAdd}
            registeredUserIds={registeredUserIds}
        />
      </>
  );
}

function RowActions({row, onRemove}) {

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showRemoveParticipantConfirmation, setShowRemoveParticipantConfirmation] = useState(
      false);
  const [isSubmittingRemoveParticipant, setIsSubmittingRemoveParticipant] = useState();

  const participantId = row?.id;

  function handleRemoveParticipant() {

    setIsSubmittingRemoveParticipant(true);

    tournamentsRegistrationsParticipantRemove_POST(
        {participantId, cbSuccess, cbFail});

    function cbSuccess() {
      onRemove();
      setIsSubmittingRemoveParticipant(true);
    }

    function cbFail(e) {
      enqueueSnackbar('Error removing participant', {variant: 'error'});
      setIsSubmittingRemoveParticipant(true);

    }
  }

  return (
      <>
        <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
        >
          <MoreVert/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
        >
          <MenuItem
              onClick={() => {
                setShowRemoveParticipantConfirmation(true);
                setAnchorEl(null);
              }}
          >
            <ListItemIcon>
              <PersonRemove/>
            </ListItemIcon>
            <ListItemText>
              {t('tournaments.registrations.action.removeParticipant')}
            </ListItemText>

          </MenuItem>
        </Menu>
        <DialogConfirmation
            variant={'error'}
            isOpen={showRemoveParticipantConfirmation}
            isSubmitting={isSubmittingRemoveParticipant}
            dialogTitle={t(
                'tournaments.registrations.removeParticipant.confirm.title')}
            dialogContent={t(
                'tournaments.registrations.removeParticipant.confirm.content')}
            handleClose={() => setShowRemoveParticipantConfirmation(false)}
            handleConfirm={handleRemoveParticipant}
            confirmButtonLabel={t(
                'tournaments.registrations.removingParticipant.confirm.button')}
        />
      </>
  );
}

export default RegistrationsSectionRegistered;
