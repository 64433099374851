import {useDropzone} from 'react-dropzone';
import {Box} from '@mui/material';
import {useMemo} from 'react';

const imageFormats = {
  'image/jpeg': [],
  'image/png': [],
  'image/svg': [],
};

const pdfFormats = {
  'application/pdf': ['.pdf']
};

function DnDArea({onDrop, accept = 'img' || 'pdf' || 'all', disabled, children}) {

  const acceptedFormats = useMemo(() => {
    if (accept === 'img') {
      return imageFormats;
    }
    if (accept === 'pdf') {
      return pdfFormats;
    }
    if (accept === 'all') {
      return {
        ...imageFormats,
        ...pdfFormats,
      };
    }
  }, [accept]);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onDrop,
    accept: acceptedFormats,
  });
  return (
      <Box {...getRootProps()} >
        <input {...getInputProps()} disabled={disabled} />
        {children}
      </Box>
  );
}

export default DnDArea;
