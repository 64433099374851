import {useController, useFormContext} from 'react-hook-form';
import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import {useState} from 'react';

function RHFAutoComplete({
                           name,
                           options = [],
                           getOptionLabel = (option) => option.label || '',
                           isOptionEqualToValue = (
                               option, value) => option.value === value?.value,
                           label = '',
                           placeholder = '',
                           defaultValue = null,
                           rules = {},
                           disabled = false,
                           onChangeCallback = null,
                           isLoading = false,
                           multiple = false,
                         }) {

  const [open, setOpen] = useState(false);

  const {control} = useFormContext();

  const {
    field: {value, onChange, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    disabled,
  });

  return (
      <Autocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          value={value || null}
          onChange={(event, newValue) => {
            onChange(newValue);
            if (onChangeCallback) onChangeCallback(newValue);
          }}
          open={open && !isLoading}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={label}
                  placeholder={placeholder}
                  error={!!error}
                  helperText={error ? error.message : ''}
                  disabled={disabled}
                  inputRef={ref}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                          {isLoading ?
                              <CircularProgress color="primary" size={20}/> :
                              null}
                          {params.InputProps.endAdornment}
                        </>
                    ),
                  }}
              />
          )}
      />
  );

}

export default RHFAutoComplete;
