import {Grid, Skeleton, Stack, Typography, useTheme} from '@mui/material';

function PageInfoItem({
                        label,
                        value,
                        showLineBreaks = false,
                        isLoading = false,
                        valueStyles={},col,
                      }) {

  const theme = useTheme();

  return (
      <Grid item xs={col ? col : 6}>
        <Stack
            sx={{
              px: '12px',
              py: '12px',
              backgroundColor: theme.palette.grey[100],
              borderRadius: '4px',
            }}
            rowGap={.25}
        >
          <Typography
              variant={'caption'}
              sx={{
                textTransform: 'capitalize',
                color: 'text.secondary',
              }}
          >
            {isLoading && <Skeleton width={'100px'}/>}
            {!isLoading && label}
          </Typography>

          <Typography
              variant={'body1'}
              sx={{
                textOverflow: 'ellipsis',
                ...(showLineBreaks) && {whiteSpace: 'pre-wrap'},
        ...valueStyles,
              }}
          >
            {isLoading && <Skeleton width={'100%'}/>}
            {!isLoading && (!!value ? value : '-')}
          </Typography>

        </Stack>


      </Grid>
  );
}

export default PageInfoItem;
