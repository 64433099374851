import TournamentsMatches
  from '../../../components/Tournaments/Matches/TournamentsMatches';

function PageTournamentsDetailMatches({tournamentDetails}) {

  return (
      <TournamentsMatches tournamentDetails={tournamentDetails} />
  );
}

export default PageTournamentsDetailMatches;
