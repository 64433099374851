export function getLocalizedNum(locale, num) {

  if (isNaN(num) || typeof num === 'undefined') {
    num = 0;
  }

  let intlLocale;

  if (locale === 'en') intlLocale = 'en-US';
  if (locale === 'nl') intlLocale = 'nl-NL';
  if (locale === 'de') intlLocale = 'de';
  if (locale === 'es') intlLocale = 'es-ES';
  if (locale === 'tr') intlLocale = 'tr-TR';
  if (locale === 'fr') intlLocale = 'fr-FR';
  if (locale === 'it') intlLocale = 'it-IT';

  return new Intl.NumberFormat(intlLocale, {
    minimumFractionDigits: 2,
  }).format(num);

}

export function getOrdinal(number, locale = 'en') {

  if (typeof number !== 'number' || isNaN(number)) {
    return '-';
  }

  if (locale === 'en') {
    // English ordinal suffixes
    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + 'th';
    }
    switch (number % 10) {
      case 1:
        return number + 'st';
      case 2:
        return number + 'nd';
      case 3:
        return number + 'rd';
      default:
        return number + 'th';
    }
  }

  if (locale === 'nl') {
    // Dutch ordinal suffixes
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits === 0 || (lastTwoDigits >= 11 && lastTwoDigits <= 19)) {
      return number + 'e';
    } else {
      switch (lastDigit) {
        case 1:
          return number + 'e';
        case 2:
          return number + 'de';
        case 3:
          return number + 'de';
        case 4:
          return number + 'de';
        default:
          return number + 'e';
      }
    }
  }

}

export function getLanguageFromLocale(locale) {
  switch (locale) {
    case 'nl':
      return 'Dutch';
    case 'en' :
      return 'English';
    case 'it' :
      return 'Italian';
    case 'fr' :
      return 'French';
    case 'es' :
      return 'Spanish';
    case 'tr' :
      return 'Turkish';
    case 'de' :
      return 'German';
    default:
      break;
  }
}

export function getCurrencyFromKey(key) {
  switch (key) {
    case 'EUR':
      return 'Euro';
    default:
      break;
  }
}





