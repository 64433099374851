export function hotjarInitialize(){

  // Initialize Hotjar if not already initialized

  if (!window.hj) {

    const hotjarScript = document.createElement('script');
    hotjarScript.type = 'text/javascript';
    hotjarScript.async = true;
    hotjarScript.id= 'hotjar-script'

    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5247359,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

    document.head.appendChild(hotjarScript);

  }
}

export function hotjarDestroy(){

  const mainScript =  document.querySelector('#hotjar-script');
  const secondScript = document.querySelector('script[src*="hotjar"]');

  if (mainScript) document.head.removeChild(mainScript);
  if (secondScript) document.head.removeChild(secondScript);

  window.hj = null; // Clear Hotjar instance
}
