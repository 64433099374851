import {Chip} from '@mui/material';
import {useTranslate} from '@tolgee/react';

function TournamentStatusChip({status, size = 'small'}) {

  const {t} = useTranslate();

  let label;
  let color;

  if (status === 'active') {
    label = t('global.types.status.active')
    color = 'success'
  }

  if (status === 'in_active') {
    label =  t('global.types.status.inactive');
    color = 'error'
  }

  if (status === 'closed') {
    label =  t('global.types.status.closed');
    color = 'default'
  }

  return (<Chip
      label={label}
      color={color}
      size={size}
  />);

}

export default TournamentStatusChip;
