import {LinearProgress} from '@mui/material';

function SelectLinearProgress(){
  return <LinearProgress
      color={'primary'}
      sx={{
        height: '3px',
        position: 'absolute',
        bottom: 0,
        width: '100%',
      }}
  />
}

export default SelectLinearProgress;
