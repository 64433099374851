import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import {useTranslate} from '@tolgee/react';
import {useEffect, useState} from 'react';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import {tournamentsList_GET} from '../../../api/tournaments';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import useDefaults from '../../../hooks/defaults/useDefaults';
import {Stack, Typography} from '@mui/material';
import {LockOpenOutlined, LockOutlined} from '@mui/icons-material';
import TournamentStatusChip from '../TournamentStatusChip';

function TournamentsList({type = 'active' || 'past'}) {

  const {t} = useTranslate();
  const navigateTo = useNavigate();

  const {locale} = useLocale();
  const {defaultCurrencySymbol} = useDefaults();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [rows, setRows] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      ...columnTypeDefaults,
      field: 'name',
      headerName: t('tournaments.list.column.tournamentName'),
      flex: 1,
      valueGetter: (_, row) => {
        return row?.name;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'startDate',
      headerName: t('tournaments.list.column.startDate'),
      flex: 1,
      valueGetter: (_, row) => {
        return dayjs(row?.startDate).format('ddd, DD MMM YY');
      },
    },
    {
      ...columnTypeDefaults,
      field: 'endDate',
      headerName: t('tournaments.list.column.endDate'),
      flex: 1,
      valueGetter: (_, row) => {
        return dayjs(row?.endDate).format('ddd, DD MMM YY');
      },
    },
    {
      ...columnTypeDefaults,
      field: 'time',
      headerName: t('tournaments.list.column.time'),
      flex: 1,
      valueGetter: (_, row) => {
        const tStart = dayjs(row?.startTime, 'HH:mm:ss').format('LT');
        const tEnd = dayjs(row?.endTime, 'HH:mm:ss').format('LT');
        return `${tStart} - ${tEnd}`;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'activeSlots',
      headerName: t('tournaments.list.column.activeSlots'),
      flex: 1,
      valueGetter: (_, row) => {
        return row?.activeSlots;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'maxSlots',
      headerName: t('tournaments.list.column.maxSlots'),
      flex: 1,
      valueGetter: (_, row) => {
        return row?.maxSlots;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'tournamentFormat',
      headerName: t('tournaments.list.column.tournamentFormat'),
      flex: 1,
      valueGetter: (_, row) => {
        return t(`tournaments.types.formats.${row?.format}`);
      },
    },
    {
      ...columnTypeDefaults,
      field: 'tournamentType',
      headerName: t('tournaments.list.column.tournamentType'),
      flex: 1,
      valueGetter: (_, {type}) => {
        return type;
      },
      renderCell: ({row: {type}}) => {

        let Icon = type === 'private' ? LockOutlined : LockOpenOutlined;

        return (
            <Stack
                direction={'row'}
                alignItems={'center'}
                height={'100%'}
                columnGap={.5}
            >
              <Icon
                  fontSize={'small'}
                  color={'primary'}
                  sx={{
                    position: 'relative',
                    top: -1,
                  }}
              />
              <Typography color={'inherit'} variant={'body2'} lineHeight={1}>
                {t(`tournaments.types.type.${type}`)}
              </Typography>
            </Stack>
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'totalRevenue',
      headerName: t('tournaments.list.column.totalRevenue'),
      flex: 1,
      valueGetter: (_, row) => {
        return row?.totalRevenue;
      },
      renderCell: ({row}) => {
        let revenue = row?.totalRevenue;
        let output = '-';
        if (revenue) {
          output = `${defaultCurrencySymbol} ${getLocalizedNum(locale,
              revenue)}`;
        }
        return output;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'status',
      headerName: t('tournaments.list.column.status'),
      flex: 1,
      valueGetter: (_, row) => {
        return row?.status;
      },
      renderCell: ({row: {status}}) => {
        return <TournamentStatusChip status={status}/>;
      },
    },
  ];

  function fetchRows() {

    setIsLoading(true);
    tournamentsList_GET({clubId, type, cbSuccess, cbFail});

    function cbSuccess(res) {
      const {rows} = res;
      setRows(rows);
      setIsLoading(false);
    }

    function cbFail(e) {
      setRows([]);
      setIsLoading(false);
    }
  }

  function handleRowClick({row}) {
    navigateTo(`/tournaments/detail/${row?.id}`);
  }

  useEffect(() => {
    fetchRows();
  }, [clubId]);

  return (
      <DataGridReusable
          rows={rows}
          isLoading={isLoading}
          columns={columns}
          handleRowClick={handleRowClick}
          showRowCursorPointer={true}

      />
  );
}

export default TournamentsList;
