import {Box, Grid, Slider, Stack, Switch, Typography} from '@mui/material';
import PageSection from '../../_Global/Page/PageSection';
import {FlagOutlined} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import PageSectionTitle from '../../_Global/Page/PageSectionTitle';
import PageInfoItem from '../../_Global/Page/PageInfoItem';
import {getSliderMarks} from '../../../utils/slider';
import dayjs from 'dayjs';
import UploadedFileList
  from '../../_Global/Uploads/UploadedFileList/UploadedFileList';

function TournamentDetailDetails({details}) {

  const {t} = useTranslate();

  const {
    enablePlayerSide,
    name,
    description,
    type,
    format,
    scoringFormat,
    tournamentCategories,
    tournamentOrganisers,
    tournamentCourts,
    sport,
    startDate,
    startTime,
    endDate,
    endTime,
    registrationDeadlineDate,
    registrationDeadlineTime,
    internalNotes,
    tournamentAssets,
  } = details || {};

  console.log('deasdasd', details);

  const categories = tournamentCategories.map(
      (tc) => t(`tournaments.types.categories.${tc.type}`),
  )?.join(', ');

  const organizers = tournamentOrganisers?.map((o) => {
    const admin = o.clubAdmin;
    return `${admin?.firstName} ${admin?.lastName}`;
  })?.join(', ');

  const courts = tournamentCourts.map((tc) => tc?.court?.name)?.
      join(', ');

  const galleryImages = tournamentAssets?.filter((a) => a.type === 'image') ||
      [];
  const attachments = tournamentAssets?.filter((a) => a.type === 'pdf') || [];

  const registrationType = tournamentCategories[0]?.registrationType;
  const participantCount = tournamentCategories[0]?.participantCount;
  const participantPrice = tournamentCategories[0]?.price;
  const minRating = tournamentCategories[0]?.minRating;
  const maxRating = tournamentCategories[0]?.maxRating;

  return (
      <Box>
        <PageSection>
          <Stack direction="row" spacing={2} alignItems="center"
                 justifyContent="space-between" mb={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <FlagOutlined/>
              <Typography variant={'h6'}>
                {t('tournaments.create.displayOnClubPage')}
              </Typography>
            </Stack>
            <Switch color={'success'} checked={enablePlayerSide} disabled/>
          </Stack>
        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.basicInfo')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                label={t('tournaments.create.tournamentName')}
                value={name}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.description')}
                value={description}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentType')}
                value={t(`tournaments.types.type.${type}`)}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentFormat')}
                value={t(`tournaments.types.formats.${format}`)}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.scoringFormat')}
                value={scoringFormat}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentCategory')}
                value={categories}
                col={12}
            />
            <PageInfoItem
                label={
                  registrationType === 'single' ?
                      t('tournaments.create.numberOfParticipants') :
                      t('tournaments.create.numberOfTeams')
                }
                value={participantCount}
                col={6}
            />
            <PageInfoItem
                label={
                  registrationType === 'single' ?
                      t('tournaments.create.pricePerParticipant') :
                      t('tournaments.create.pricePerTeam')
                }
                value={participantPrice}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentOrganizer')}
                value={organizers}
                col={12}
            />

          </Grid>
        </PageSection>

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.sport')}
          />
          <Grid container>
            <PageInfoItem
                label={t('tournaments.create.sport')}
                value={sport}
                col={12}
            />
          </Grid>
        </PageSection>
        {
            ((minRating || minRating === 0) &&
                (maxRating || maxRating === 0)) &&
            <PageSection>
              <PageSectionTitle
                  text={t('tournaments.create.section.ratingRange')}
                  titleMeta={
                    <Switch disabled checked={true}/>
                  }
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box px={1.5}>
                    <Slider
                        disabled
                        min={minRating}
                        max={maxRating}
                        marks={getSliderMarks(minRating, maxRating, 1, '')}
                        value={[minRating, maxRating]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PageSection>
        }
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.detail.section.assignedCourts')}
          />
          <PageInfoItem
              label={t('tournaments.create.courts')}
              value={courts}
              col={12}
          />
        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.dateTime')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                label={t('tournaments.create.tournamentStartDate')}
                value={dayjs(startDate).format('ddd MMM D, YYYY')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentEndDate')}
                value={dayjs(endDate).format('ddd MMM D, YYYY')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentStartTime')}
                value={dayjs(startTime, 'HH:mm:ss').format('LT')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentEndTime')}
                value={dayjs(endTime, 'HH:mm:ss').format('LT')}
                col={6}
            />
          </Grid>

        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.registrationDeadline')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                label={t('tournaments.create.registrationDeadlineDate')}
                value={dayjs(registrationDeadlineDate).
                    format('ddd MMM D, YYYY')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.registrationDeadlineTime')}
                value={dayjs(registrationDeadlineTime, 'HH:mm:ss').format('LT')}
                col={6}
            />

          </Grid>

        </PageSection>

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.internalNotes')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                value={internalNotes}
                col={12}
            />
          </Grid>
        </PageSection>

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.gallery')}
          />
          <UploadedFileList
              type={'preview'}
              emptyText={'There are no images to view'}
              files={galleryImages}
          />
        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.attachments')}
          />
          <UploadedFileList
              type={'list'}
              emptyText={'There are no files to view'}
              files={attachments}
          />
        </PageSection>
      </Box>
  );
}

export default TournamentDetailDetails;
