import {useTranslate} from '@tolgee/react';
import {useTournamentsScoreboardContext} from './TournamentsScoreBoardProvider';
import {Stack, Tab, Tabs} from '@mui/material';

export function ScoreBoardCategorySelect() {

  const {t} = useTranslate();

  const {
    categories,
    selectedCategory,
    handleSelectCategory,
  } = useTournamentsScoreboardContext();

  const selectedCategoryIdx = categories?.findIndex(
      (c) => c.id === selectedCategory.id);

  function handleOnChange(_, newIndex) {
    const newCategory = categories[newIndex];
    handleSelectCategory(newCategory);
  }

  return (
      <>
        <Stack
            direction="row"
            justifyContent="center"
            sx={{
              backgroundColor: '#F0FAFF',
              px: 2,
              pt: 2,
            }}
        >
          <Tabs
              variant={'scrollable'}
              value={selectedCategoryIdx}
              onChange={handleOnChange}
              TabIndicatorProps={{
                sx: {
                  background: '#fff',
                  height: '100%',
                  borderRadius: '8px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  boxShadow: 6,
                },
              }}
              sx={{
                '& .MuiTab-root': {
                  zIndex: 1,
                  margin: 0,
                },
              }}
          >
            {
              categories?.map((category, index) => {
                return (
                    <Tab
                        key={index}
                        label={t(
                            `tournaments.types.categories.${category.type}`)}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          p: 2,
                          opacity: '1 !important',
                        }}
                    />
                );
              })
            }
          </Tabs>
        </Stack>
      </>
  );

}
