import {Stack} from '@mui/system';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material';
import Empty from '../../Empty/Empty';
import {useMemo, useState} from 'react';
import {Clear, PictureAsPdf} from '@mui/icons-material';

function UploadedFileList({
                            files,
                            type = 'preview' || 'list',
                            emptyText = '',
                            onDelete,
                          }) {

  const filtered = useMemo(() => {
    return files?.filter((f) => !f?.markedForDelete);
  }, [files]);

  if (!filtered || filtered?.length === 0) {
    return (
        <Empty
            imageStyle={'simple'}
            description={emptyText}
        />
    );
  }

  if (type === 'preview') {
    return (
        <Grid container spacing={2}>
          {
            filtered?.map((file, index) => {
              return (
                  <Grid key={index} item xs={3}>
                    <ImageAttachment
                        file={file}
                        onDelete={onDelete}
                    />
                  </Grid>
              );
            })
          }
        </Grid>
    );
  }

  if (type === 'list') {
    return <Stack spacing={1}>
      {
        filtered.map((file, index) => {
          return (
              <FileAttachment
                  key={index}
                  file={file}
                  onDelete={onDelete}
              />
          );
        })
      }
    </Stack>;
  }

}

function ImageAttachment({file, onDelete}) {

  const theme = useTheme();
  const {imageId, url, path} = file;

  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const allowDelete = !!onDelete;

  return (
      <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.grey[100],
            backgroundImage: `url(${url || path})`,
            backgroundSize: 'cover',
            borderRadius: '16px',
            color: '#fff',
            '&:hover': {
              '.overlay': {
                opacity: .3,
              },
            },
          }}
          onMouseEnter={() => setShowDelete(true)}
          onMouseLeave={() => setShowDelete(false)}
      >
        {
            isDeleting &&
            <CircularProgress color={'error'} size={26} sx={{
              zIndex: 1,
            }}/>
        }
        {
            allowDelete &&
            <Box
                className={'overlay'}
                sx={{
                  backgroundColor: '#000',
                  opacity: 0,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  borderRadius: '16px',
                  transition: 'all .3s',
                  zIndex: 0,
                }}
            >
            </Box>
        }
        {
            allowDelete &&
            <Box
                sx={{
                  position: 'absolute',
                  top: '6px',
                  right: '6px',
                  zIndex: 1,
                }}
            >
              <Zoom
                  in={showDelete && !isDeleting}
              >
                <Box
                    sx={{
                      backgroundColor: theme.palette.error.main,
                      color: '#fff',
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onDelete(file, setIsDeleting);
                    }}
                >
                  <Clear color={'inherit'} fontSize={'small'}/>
                </Box>
              </Zoom>
            </Box>

        }
      </Box>
  );
}

function FileAttachment({file, onDelete}) {

  const theme = useTheme();
  const {imageId, url, key} = file;

  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const allowDelete = !!onDelete;

  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          sx={{
            py: .5,
          }}
          onMouseEnter={() => setShowDelete(true)}
          onMouseLeave={() => setShowDelete(false)}
      >
        <PictureAsPdf color={'action'} fontSize={'medium'}/>
        <Typography>
          {key?.substring(39)}
        </Typography>

        {
            !isDeleting &&
            <Zoom in={!!allowDelete && showDelete}>
              <Box
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    color: '#fff',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    onDelete(file, setIsDeleting);
                  }}
              >
                <Clear color={'inherit'} fontSize={'small'}/>
              </Box>
            </Zoom>
        }
        {
            isDeleting &&
            <CircularProgress size={16} color={'error'}/>
        }

      </Stack>
  );
}

export default UploadedFileList;



