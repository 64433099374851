import {useController, useFormContext} from 'react-hook-form';
import {Slider} from '@mui/material';
import {useMemo} from 'react';
import {getSliderMarks} from '../../../utils/slider';

function RHFSlider({
                     name,
                     min = 0,
                     max = 10,
                     step = 1,
                     minDistance = 1,
                     marks,
                     autoGeneratedMarksStep = 1,
                     autoGeneratedMarksLabelSuffix = '',
                     defaultValue,
                     disabled = false,
                     valueLabelDisplay = 'auto' || 'on' || 'off',
                   }) {

  const {control} = useFormContext();

  const {
    field: {value, onChange},
  } = useController({
    name: name,
    control,
    defaultValue,
    disabled,
  });

  const autoGeneratedMarks = useMemo(() => {

    return getSliderMarks(min,max,autoGeneratedMarksStep,autoGeneratedMarksLabelSuffix);

  }, [min, max, autoGeneratedMarksStep, autoGeneratedMarksLabelSuffix]);

  function handleOnChange(event, newValue, activeThumb) {

    if (value?.length === 2) {
      if (activeThumb === 0) {
        onChange([Math.min(newValue[0], value[1] - minDistance), value[1]]);
      } else {
        onChange([value[0], Math.max(newValue[1], value[0] + minDistance)]);
      }
    } else {
      onChange(newValue);
    }

  }

  return (
      <Slider
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          marks={marks || autoGeneratedMarks}
          value={value}
          valueLabelDisplay={valueLabelDisplay}
          onChange={handleOnChange}
      />
  );

}

export default RHFSlider;
