import {createContext, useContext, useMemo} from 'react';

const CourtsAvailabilityContext = createContext();

function CourtsAvailabilityListProvider({
                                          type = 'create' || 'detail',
                                          courts,
                                          isLoadingCourts,
                                          tableMaxHeight,
                                          children,
                                        }) {

  const conflictingCourts = useMemo(() => {
    return courts?.filter((c) => {
      return c.status === 'fail';
    });
  }, [courts]);

  const courtsCount = courts?.length;
  const conflictingCourtsCount = conflictingCourts?.length;

  return (
      <CourtsAvailabilityContext.Provider
          value={{
            type,
            courts,
            isLoadingCourts,
            courtsCount,
            conflictingCourtsCount,
            tableMaxHeight,
          }}
      >
        {children}
      </CourtsAvailabilityContext.Provider>
  );
}

export default CourtsAvailabilityListProvider;

export function useCourtsAvailabilityListContext() {
  const {
    type,
    courts,
    isLoadingCourts,
    courtsCount,
    conflictingCourtsCount,
    tableMaxHeight,
  } = useContext(CourtsAvailabilityContext);

  return {
    type,
    courts,
    isLoadingCourts,
    courtsCount,
    conflictingCourtsCount,
    tableMaxHeight,
  };
}
