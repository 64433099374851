import RegistrationsSection from './RegistrationsSection';
import {useTranslate} from '@tolgee/react';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import PageCard from '../../_Global/Page/PageCard/PageCard';
import dayjs from 'dayjs';

function RegistrationsSectionInvited({rows, loading}) {

  const {t} = useTranslate();

  const label = t('tournaments.registrations.section.invited');

  const columns = [
    {
      ...columnTypeDefaults,
      field: 'email',
      headerName: t('tournaments.registrations.table.column.email'),
      valueGetter: (_, row) => {
        return row?.user?.email;
      },
    },
    {
      ...columnTypeDefaults,
      field: 'invitationDate',
      headerName: t('tournaments.registrations.table.column.invitationDate'),
      valueGetter: (_, row) => {
        return dayjs(row?.createdAt).format('ddd, DD MMM YY');
      },
    },
  ];

  return (
      <RegistrationsSection
          label={label}
          count={rows?.length}
      >
        <PageCard>
          <DataGridReusable
              isLoading={loading}
              columns={columns}
              rows={rows}
          />
        </PageCard>
      </RegistrationsSection>
  );
}

export default RegistrationsSectionInvited;
