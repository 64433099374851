import {createTheme, ThemeProvider} from '@mui/material';
import useLocale from '../hooks/localization/useLocale';
import {useMemo} from 'react';
import {enUS, esES, frFR, itIT, nlNL} from '@mui/material/locale';
import {
  enUS as enUSDG,
  esES as esESDG,
  frFR as frFRDG,
  itIT as itITDG,
  nlNL as nlNLDG,
} from '@mui/x-data-grid/locales';
import {
  enUS as enUSDP,
  esES as esESDP,
  frFR as frFRDP,
  itIT as itITDP,
  nlNL as nlNLDP,
} from '@mui/x-date-pickers/locales';
import theme from '../vendor/mui/theme/theme';

function ProviderTheme({children}) {

  const locales = {
    en: enUS,
    nl: nlNL,
    es: esES,
    fr: frFR,
    it: itIT,
  };

  const datePickerLocales = {
    en: enUSDP,
    nl: nlNLDP,
    es: esESDP,
    fr: frFRDP,
    it: itITDP,
  }

  const dataGridLocales = {
    en: enUSDG,
    nl: nlNLDG,
    es: esESDG,
    fr: frFRDG,
    it: itITDG,
  }

  const {locale} = useLocale();

  const themeWithLocale = useMemo(
      () => createTheme(theme, locales[locale], datePickerLocales[locale], dataGridLocales[locale]),
      [locale, theme],
  );

  return (
      <ThemeProvider theme={themeWithLocale}>
        {children}
      </ThemeProvider>
  );

}

export default ProviderTheme;
