import {statusError, statusLoading, statusSuccess} from './status';
import {
  GET_SPORTS_FAILURE,
  GET_SPORTS_LOADING,
  GET_SPORTS_SUCCESS,
} from '../constants';
import axios from 'axios';

export const sportsList_GET = (id, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const url = id ? `sports/list/club?clubId=${id}` : `sports/list?limit=1000`;
      dispatch(statusLoading(GET_SPORTS_LOADING));
      const response = await axios.get(url);
      dispatch(statusSuccess(GET_SPORTS_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e)
      dispatch(statusError(GET_SPORTS_FAILURE, e));
    }
  };
};
