import {Box} from '@mui/material';

function ContainerFixed({
                          maxWidth = '1200px',
                          width,
                          justify = 'center',
                          children,
                        }) {
  return <Box
      sx={{
        ...(justify === 'center') && {margin: '0 auto'},
        ...(justify === 'left') && {margin: '0'},
        maxWidth: maxWidth,
        width: '100%',
        paddingLeft: '4px',
        paddingRight: '4px',
      }}
  >
    {children}
  </Box>;
}

export default ContainerFixed;
