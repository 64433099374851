import {useController, useFormContext} from 'react-hook-form';
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';

function RHFSelectMultiple({
                             name,
                             label,
                             rules,
                             disabled = false,
                             options,
                             variant = 'outlined',
                             size = 'medium' || 'small',
                             isLoading = false,
                             onChangeCallback = () => {
                             },
                           }) {

  const {control} = useFormContext();

  const {
    field: {value = [], onChange, ref},
    fieldState: {error},
  } = useController({
    control,
    name,
    rules,
  });

  return (
      <FormControl fullWidth size={size} disabled={disabled}>
        <InputLabel error={!!error}>{label}</InputLabel>
        <Select
            inputRef={ref}
            value={value || []}
            disabled={disabled}
            error={!!error}
            label={label}
            multiple
            variant={variant}
            onChange={(event, child) => {
              onChange(event.target.value);
              onChangeCallback(event.target.value);
            }}
            sx={{
              '& .MuiSelect-icon': {
                ...(disabled && {
                  display: 'none',
                }),
              },
            }}
            endAdornment={
              <>
                {
                    isLoading &&
                    <InputAdornment position="end" sx={{
                      marginRight: '20px',
                    }}>
                      <CircularProgress size={20}/>
                    </InputAdornment>
                }
                {
                    !isLoading && disabled &&
                    <InputAdornment
                        position="end"
                    >
                      <LockOutlined color={'disabled'}/>
                    </InputAdornment>
                }
              </>

            }
            renderValue={(selected) => {
              return (
                  <Stack
                      direction={'row'}
                      flexWrap={'wrap'}
                      columnGap={1}
                      rowGap={1}
                  >
                    {
                      selected?.map((value) => {

                        const option = options?.find(
                            (option) => option?.value === value);

                        if (!option) return;

                        return (
                            <Chip
                                color={'default'}
                                size={'small'}
                                key={value}
                                label={option?.label}
                            />
                        );

                      })
                    }
                  </Stack>
              );
            }}
        >
          {
            options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={value?.includes(option.value) || false}/>
                  <ListItemText primary={option.label}/>
                </MenuItem>
            ))
          }
        </Select>
        {
            !!error &&
            <FormHelperText error>
              {error?.message}
            </FormHelperText>}
      </FormControl>
  );

}

export default RHFSelectMultiple;
