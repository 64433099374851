import PageCard from '../../_Global/Page/PageCard/PageCard';
import TournamentsScoreBoardProvider from './TournamentsScoreBoardProvider';
import {ScoreBoardScoreList} from './TournamentsScoreBoardList';
import {ScoreBoardCategorySelect} from './TournamentsScoreBoardCategorySelect';
import {ScoreBoardHeader} from './TournamentsScoreBoardHeader';

function TournamentsScoreBoard({tournamentId, categories}) {

  return (
      <TournamentsScoreBoardProvider
          tournamentId={tournamentId}
          categories={categories}
      >
        <PageCard>
          <ScoreBoardCategorySelect/>
          <ScoreBoardHeader/>
          <ScoreBoardScoreList/>
        </PageCard>
      </TournamentsScoreBoardProvider>
  );
}

export default TournamentsScoreBoard;
