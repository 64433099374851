import {statusError, statusLoading, statusSuccess} from './status';
import {
  GET_COURT_FAILURE,
  GET_COURT_LOADING,
  GET_COURT_SUCCESS,
} from '../constants';
import axios from 'axios';

export const courtsList_GET = (clubId, limit, pageNo, sport, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COURT_LOADING));
      const sortBy = 'name';
      const orderBy = 'ASC';
      const response = await axios.get(
          `courts/list?sortBy=${sortBy}&orderBy=${orderBy}&clubId=${clubId}&version=2&limit=${limit}&page=${pageNo}&sport=${sport}`);
      dispatch(statusSuccess(GET_COURT_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_COURT_FAILURE, e));
    }
  };
};

export const courtsListByID_GET = (id, cbSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COURT_LOADING));
      const response = await axios.get(`courts/${id}`);
      dispatch(statusSuccess(GET_COURT_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbSuccess(e);
      dispatch(statusError(GET_COURT_FAILURE, e));
      console.log(e);
    }
  };
};

export const courtsListBySports_GET = (clubId, cbCallBack) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COURT_LOADING));
      const response = await axios.get(`courts/per-sport/${clubId}`);
      dispatch(statusSuccess(GET_COURT_SUCCESS, response.data));
      cbCallBack(response);
    } catch (e) {
      cbCallBack(e);
      dispatch(statusError(GET_COURT_FAILURE, e));
      console.log(e);
    }
  };
};
