import {
  Box,
  debounce,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {Clear, Search} from '@mui/icons-material';
import {useTournamentsScoreboardContext} from './TournamentsScoreBoardProvider';

export function ScoreBoardHeader() {
  return (
      <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            px: 2,
            py: 2,
          }}
      >
        <ScoreListSearch/>
      </Stack>
  );
}

function CurrentRound() {
  return (
      <Stack
          direction="row"
          alignItems="center"
          spacing={.25}

      >
        <Typography
            variant="h6"
            component="div"
            fontWeight={800}
            sx={{
              display: 'flex',
              columnGap: .5,
            }}
        >
          <Box component={'span'}>Round 2</Box>
          <Box component={'span'} color={'text.disabled'}>
            /
          </Box>
          <Box component={'span'} color={'text.disabled'}>
            3
          </Box>
        </Typography>
      </Stack>
  );
}

function ScoreListSearch() {

  const {
    searchRef,
    searchTerm,
    setSearchTerm,
    handleClearSearch,
    scores,
  } = useTournamentsScoreboardContext();

  const disabled = !scores || scores?.length === 0;

  const debounceSetSearchTerm = debounce((value) => {
    setSearchTerm(value);
  }, 500);

  return (
      <TextField
          sx={{width: '280px'}}
          disabled={disabled}
          inputRef={searchRef}
          onChange={(event) => {
            debounceSetSearchTerm(event.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position={'start'}>
              <Search color={'action'}/>
            </InputAdornment>,
            endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <Clear/>
                  </IconButton>
                </InputAdornment>
            ),
          }}
          placeholder={'Search...'}
      />
  );
}
