import {Box, Typography} from '@mui/material';

function ChipRating({rating}) {
  return (
      <Box
          sx={{
            background: 'linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
            borderRadius: '8px',
            lineHeight: 1,
            py: .25,
            px: 1,
          }}
      >
        <Typography
            fontSize={'13px'}
            fontWeight={700}
            sx={{
              color: '#fff',
            }}
        >
          {rating}
        </Typography>
      </Box>
  );
}

export default ChipRating;
