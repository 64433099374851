function PlumpyRepeat(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
        <path
            d="M21.283,5.29l-3.104-3.075C17.742,1.782,17,2.092,17,2.706V4H9c-3.314,0-6,2.686-6,6c0,1.104,0.895,2,2,2s2-0.896,2-2c0-1.105,0.895-2,2-2h8v1.294c0,0.615,0.742,0.924,1.178,0.491l3.104-3.075C21.678,6.319,21.678,5.681,21.283,5.29z"
            opacity=".35" fill="currentColor"/>
        <path
            d="M3.296,18.724l3.104,3.075c0.437,0.432,1.178,0.123,1.178-0.491v-1.294h8c3.314,0,6-2.686,6-6c0-1.104-0.895-2-2-2s-2,0.896-2,2c0,1.105-0.895,2-2,2h-8v-1.294c0-0.615-0.742-0.924-1.178-0.491l-3.104,3.075C2.901,17.694,2.901,18.332,3.296,18.724z"
            fill="currentColor"/>
      </svg>
  );
}

export default PlumpyRepeat;
