import {Grid} from '@mui/material';
import PageCard from '../../../components/_Global/Page/PageCard/PageCard';
import PageCardContent
  from '../../../components/_Global/Page/PageCard/PageCardContent';
import CourtsAvailabilityListProvider
  from '../../../components/CourtsAvailability/CourtsAvailabilityListProvider';
import CourtsAvailabilityList
  from '../../../components/CourtsAvailability/CourtsAvailabilityList';
import TournamentDetailDetails
  from '../../../components/Tournaments/Detail/TournamentDetailDetails';
import PageCardActions
  from '../../../components/_Global/Page/PageCard/PageCardActions';
import TournamentDetailActions
  from '../../../components/Tournaments/Detail/TournamentDetailActions';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {tournamentsBookings_GET} from '../../../api/tournaments';

function PageTournamentsDetailTournamentDetail({details}) {

  const {tournamentId} = useParams();

  const [loadingBookings,setLoadingBookings] = useState(true);
  const [bookings, setBookings] = useState();

  function fetchBookings() {

    setLoadingBookings(true);
    tournamentsBookings_GET({tournamentId, cbSuccess, cbFail});

    function cbSuccess(res) {
      setBookings(res);
      setLoadingBookings(false);
    }

    function cbFail(e) {
      setLoadingBookings(false);
    }
  }

  useEffect(() => {
    fetchBookings();
  }, [tournamentId]);

  return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PageCard>
            <PageCardContent>
              <TournamentDetailDetails details={details}/>
            </PageCardContent>
            <PageCardActions>
              <TournamentDetailActions details={details}/>
            </PageCardActions>
          </PageCard>
        </Grid>
        <CourtsAvailabilityListProvider
            type={'detail'}
            isLoadingCourts={loadingBookings}
            courts={bookings}
            tableMaxHeight={'calc(100vh - 220px)'}
        >
          <Grid item xs={6}>
            <PageCard sx={{
              position: 'sticky',
              top: '20px',
            }}>
              <PageCardContent>
                <CourtsAvailabilityList/>
              </PageCardContent>
            </PageCard>
          </Grid>
        </CourtsAvailabilityListProvider>
      </Grid>
  );
}

export default PageTournamentsDetailTournamentDetail;
