import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_WALLET} from '../../constants/modules';
import { lazy } from 'react';
const PageWalletsOffersList = lazy(()=>import('../../pages/Wallets/PageWalletsOffersList'));
const  PageWalletsOffersAddEdit = lazy(()=> import( '../../pages/Wallets/PageWalletsOffersAddEdit'));
const  PageWalletsOffersDetail = lazy(()=> import( '../../pages/Wallets/PageWalletsOffersDetail'));

const RoutesOffers = (
    <>
        {/*Wallets Module*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_WALLET}
        />}>
            <Route
                path={`/offers`}
                element={<PageWalletsOffersList/>}
            />
            <Route
                path={`/offers/detail`}
                element={<PageWalletsOffersDetail/>}
            />


        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_WALLET}
        />}>
            <Route 
                path={`/offers/edit`}
                element={<PageWalletsOffersAddEdit mode={'edit'}/>}
                />
                <Route
                path={`/offers/add`}
                element={<PageWalletsOffersAddEdit mode={'add'}/>}
                /> 

        </Route>
    </>
)

export default RoutesOffers;
