import {useController} from 'react-hook-form';
import React, {useState} from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  debounce,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {bookingsCustomers_GET} from '../../../vendor/redux/actions/bookings';
import {useDispatch} from 'react-redux';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import {CheckCircleOutline} from '@mui/icons-material';

function RHFPlayerSearch({
                           name,
                           label,
                           placeholder,
                           maxParticipants,
                           filterUserIds = [],
                           rules,
                         }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {field, fieldState: {error}} = useController({
    name,
    rules,
  });

  const {ref} = field;

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  label = label || t('playerSearch.label');

  async function fetchOptions(searchTerm) {

    setLoading(true);

    await dispatch(
        bookingsCustomers_GET(searchTerm?.trim(), cbSuccess, cbFail));

    function cbSuccess(res) {
      const response = res?.data?.data;
      setOptions(response);
      setLoading(false);
    }

    function cbFail(e) {
      setLoading(false);
    }

  }

  const debouncedFetchOptions = debounce(fetchOptions, 500);

  const readOnly = maxParticipants ?
      field?.value?.length >= maxParticipants :
      false;

  return (
      <Autocomplete
          {...field}
          fullWidth
          multiple
          freeSolo
          readOnly={readOnly}
          options={options}
          noOptionsText={'No results found'}
          autoHighlight
          getOptionLabel={(option) => option.email}
          filterSelectedOptions
          filterOptions={(options) => {
            return options?.filter(
                option => !filterUserIds.includes(option.userId));
          }}
          onChange={(event, value, reason) => {
            if (reason === 'clear') return field.onChange([]);
            if (options?.length === 0) return event.preventDefault();
            if (typeof value?.at(-1) === 'string') return event.preventDefault();
            field.onChange(value);
          }}
          onInputChange={(event, value) => {
            if (value) {
              debouncedFetchOptions(value);
            } else {
              setOptions([]);
            }
          }}
          isOptionEqualToValue={(option, value) => option.userId ===
              value.userId}
          loading={loading}
          renderInput={(params) => (
              <TextField
                  {...params}
                  inputRef={ref}
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label={label}
                  placeholder={!readOnly ? placeholder : undefined}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <InputAdornment position={'end'}>
                          {/*{*/}
                          {/*  loading ?*/}
                          {/*      <CircularProgress color="inherit" size={20} sx={{mr:1}}/> :*/}
                          {/*      null*/}
                          {/*}*/}
                          {
                              maxParticipants &&
                              <SelectedParticipantCount
                                  maxParticipants={maxParticipants}
                                  value={field.value}
                              />
                          }
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                    ),
                  }}
              />
          )}
          renderOption={(props, option, state) => {
            return (
                <Box
                    {...props}
                    key={option.userId}
                    sx={{
                      display: 'flex',
                      padding: '8px 16px',
                      alignItems: 'center',
                      '&:hover': {backgroundColor: theme.palette.grey[100]},
                      '&.Mui-focused': {backgroundColor: theme.palette.grey[100]},
                    }}
                >
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: 1,
                  }}>
                    <ProfileImageViewer
                        size={'xs'}
                        imgPath={option?.imageUrl}
                        placeholderStyle={'user-icon'}
                    />
                  </Box>
                  <Stack>
                    <Typography
                        variant={'body1'}
                        fontWeight={500}
                    >
                      {option.firstName} {option.lastName}
                    </Typography>
                    <Typography
                        variant={'caption'}
                        color={'text.secondary'}
                    >
                      {option.email}
                    </Typography>
                  </Stack>
                </Box>
            );
          }}
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                  <Chip
                      key={option.userId}
                      label={option.email}
                      {...getTagProps({index})}
                      sx={{
                        //to prevent end adornment to wrap on next line
                        //in case of only two items
                        maxWidth: 'calc(50% - 42px) !important',
                      }}
                      onDelete={(event) => {
                        event.preventDefault();
                        const filtered = value?.filter(
                            (v) => v.userId !== option.userId);
                        field.onChange(filtered);
                      }}
                  />
              ))
          }

          sx={{
            ".MuiAutocomplete-inputRoot .MuiAutocomplete-input":{
              minWidth:"100px",
              ...(readOnly && {
                minWidth: "0px",
              })
            }
          }}


      />
  );
}

function SelectedParticipantCount({value, maxParticipants}) {
  const participantCount = value?.length;
  const isMaxed = participantCount >= maxParticipants;

  if (isMaxed) {
    return <CheckCircleOutline color={'success'}/>;
  }

  return <Typography variant={'body2'} mr={.5}>
    {`${participantCount}/${maxParticipants}`}
  </Typography>;
}

export default RHFPlayerSearch;
