import {Route} from 'react-router-dom';
import PageTournamentsList
  from '../../pages/Tournaments/TournamentList/PageTournamentsList';
import PageTournamentsDetail
  from '../../pages/Tournaments/TournamentsDetail/PageTournamentsDetail';
import PageTournamentsAddEdit
  from '../../pages/Tournaments/TournamentsAddEdit/PageTournamentsAddEdit';

const RoutesTournaments = (
        <Route
            path="/tournaments"
        >
          {/*List*/}
          <Route
              index
              element={<PageTournamentsList initialTabValue={0}/>}
          />
          <Route
              path={'active'}
              element={<PageTournamentsList initialTabValue={0}/>}
          />
          <Route
              path={'past'}
              element={<PageTournamentsList initialTabValue={1}/>}
          />

          {/*Detail*/}
          <Route path={'detail/:tournamentId'}>
            <Route
                index
                element={<PageTournamentsDetail initialTabValue={0}/>}
            />
            <Route
                path={'tournament-detail'}
                element={<PageTournamentsDetail initialTabValue={0}/>}
            />
            <Route
                path={'registrations'}
                element={<PageTournamentsDetail initialTabValue={1}/>}
            />
            <Route
                path={'teams'}
                element={<PageTournamentsDetail initialTabValue={2}/>}
            />
            <Route
                path={'matches'}
                element={<PageTournamentsDetail initialTabValue={3}/>}
            />
            <Route
                path={'scoreboard'}
                element={<PageTournamentsDetail initialTabValue={4}/>}
            />
          </Route>

          {/*Create*/}
          <Route
              path={'create'}
              element={<PageTournamentsAddEdit mode={'add'}/>}
          />
          {/*Edit*/}
          <Route
              path={'edit/:tournamentId'}
              element={<PageTournamentsAddEdit mode={'edit'}/>}
          />

        </Route>
    )
;

export default RoutesTournaments;
