import {FormControlLabel, Switch} from '@mui/material';
import {useController, useFormContext} from 'react-hook-form';

function RHFSwitch({
                     name,
                     label,
                     onChangeCallback = () => {
                     },
                     disabled = false,
                     ...props
                   }) {

  const {control} = useFormContext();

  const {
    field: {value, onChange, onBlur},
  } = useController({
    name,
    control,
    defaultValue: false,
    disabled,
  });

  return (
      <FormControlLabel
          control={
            <Switch
                disabled={disabled}
                checked={!!value}
                onChange={(e) => {
                  onChangeCallback(e.target.checked);
                  onChange(e.target.checked);
                }}
                onBlur={onBlur}
                {...props}
            />
          }
          label={label}
      />
  );
}

export default RHFSwitch;
