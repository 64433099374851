import axios from 'axios';

export const recurBookingsValidate_POST = (
    startDate, endDate, startTime, endTime, courtId, type, day, date, eventId,trainingId, cbSuccess,
    cbFailure) => {
  const body = {
    startDate,
    endDate,
    startTime,
    endTime,
    courtId,
    type,
    day,
    date,
  };
  if(eventId ) {
    body.eventId = eventId;
  }
  if(trainingId) {
    body.trainingId = trainingId;
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(`/recurrbookings/validate`, body);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};


export const recurBookingsMultiCreate_POST = (body,cbSuccess,
    cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/recurrbookings/multi/create`, body);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const recurBookingsMultiEdit_PATCH = (body,cbSuccess,
    cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`/recurrbookings/edit-recurring-booking-title-and-notes`,body);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const addBookingToRecurring_POST = (recurringBookingId,  courtId, body, cbSuccess,
  cbFailure) => {
return async (dispatch) => {
  try {
    const response = await axios.post(`/recurrbookings/add-single-booking/${recurringBookingId}/${courtId}`, body);
    cbSuccess(response);
  } catch (e) {
    cbFailure(e);
  }
};
};

export const recurBookingsCancel_POST = (id, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/recurrbookings/cancel?id=${id}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const recurBookingsNotify_POST = (
    userArray, bookingId, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/recurrbookings/notify`, {
        userArr: userArray,
        bookingId,
      });
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const sendrecurBookingsDetail_POST = (
  userArray, bookingId, cbSuccess, cbFailure) => {
return async (dispatch) => {
  try {
    const response = await axios.post(`/recurrbookings/send-recurring-details`, {
      userArr: userArray,
      bookingId,
    });
    cbSuccess(response);
  } catch (e) {
    cbFailure(e);
  }
};
};

export const recurBookingsTypes_GET = (cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/recurrbookings/types`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const recurBookingsList_GET = (
    clubId, courtId, status, id, startTime, endTime, startDate, endDate,
    cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `/recurrbookings/list?&clubId=${clubId}&courtId=${courtId}&status=${status}&id=${id}&startTime=${startTime}&endTime=${endTime}&startDate=${startDate}&endDate=${endDate}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const recurBookingsListServerside_GET = (
    clubId, courtId, status, id, startTime, endTime, startDate, endDate,
    cbSuccess, cbFailure,page,pageSize,searchTerm) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `/recurrbookings/listAll?&clubId=${clubId}&courtId=${courtId}&status=${status}&id=${id}&startTime=${startTime}&endTime=${endTime}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${pageSize}&searchTerm=${searchTerm}`);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};


