import {Box} from '@mui/material';
import {forwardRef, useRef} from 'react';

const PageSection = forwardRef(({mb ='48px' ,children}, ref)=>{
  return (
      <Box
          ref={ref}
          component={'section'}
          sx={{
            marginBottom: mb,
          }}
      >
        {children}
      </Box>
  );
})

// function PageSection({mb = '48px', children}) {
//
// }

export default PageSection;
