import {useTranslate} from '@tolgee/react';
import RegistrationsSection from './RegistrationsSection';
import {useEffect, useState} from 'react';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import PageCard from '../../_Global/Page/PageCard/PageCard';

function RegistrationsSectionCancelled({sharedColumns,rows,loading}) {

  const {t} = useTranslate();

  const label = t('tournaments.registrations.section.cancelled');

  const columns = [
    ...sharedColumns,
  ];

  function fetchRows() {

    function cbSuccess(res) {
    }

    function cbFail(e) {
    }
  }

  useEffect(() => {
    fetchRows();
  }, []);

  return (
      <RegistrationsSection
          label={label}
          count={rows?.length}
      >
        <PageCard>
          <DataGridReusable
              isLoading={loading}
              columns={columns}
              rows={rows}
          />
        </PageCard>
      </RegistrationsSection>
  );
}

export default RegistrationsSectionCancelled;
