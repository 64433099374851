
export function isValidURL(value){
  // Regular expression to validate URLs
  const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  return urlRegex.test(value);
}

export const sortByKey=(array, key)=>{
  return array.slice().sort((a, b) => {

    const valueA = a[key];
    const valueB = b[key];

    const valueAToCheck = valueA.toLowerCase()
    const valueBToCheck = valueB.toLowerCase()

    if (valueAToCheck < valueBToCheck) return -1;
    if (valueAToCheck > valueBToCheck) return 1;
    return 0;
  });
}

export const sortArrayByKey=(array, key)=>{
  return array.slice().sort((a, b) => {
    return a[key] - b[key];
  });
}

export function validateOnlyLetters(input) {
  // This regex matches Unicode letters (including accented and special characters).
  const regex = /^[\p{L}]+$/u;
  return regex.test(input);
}