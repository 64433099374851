function FlagES() {
  return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 15">
        <path fill="#FFFFFF" d="M0,0h22.5v15H0V0z"/>
        <path fill="#D03433" d="M0,0h22.5v4H0V0z M0,11h22.5v4H0V11z"/>
        <path fill="#FBCA46" d="M0,4h22.5v7H0V4z"/>
        <path fill="#FFFFFF" d="M7.8,7h1v0.5h-1V7z"/>
        <path fill="#A41517" d="M7.2,8.5C7.2,8.8,7.5,9,7.8,9c0.3,0,0.6-0.2,0.6-0.5L8.5,7H7.1C7.1,7,7.2,8.5,7.2,8.5z M6.6,7
		c0-0.3,0.2-0.5,0.4-0.5c0,0,0,0,0,0h1.5C8.8,6.5,9,6.7,9,6.9C9,7,9,7,9,7L8.9,8.5c-0.1,0.6-0.5,1-1.1,1c-0.6,0-1-0.4-1.1-1L6.6,7
		L6.6,7z"/>
        <path fill="#A41517" d="M6.8,7.5h2V8H8.3L7.8,9L7.3,8H6.8V7.5z M5.3,6h1v3.5h-1V6z M9.3,6h1v3.5h-1V6z M6.8,5.5C6.8,5.2,7,5,7.3,5h1
		c0.3,0,0.5,0.2,0.5,0.5v0.2C8.8,5.9,8.7,6,8.5,6c0,0,0,0,0,0H7C6.9,6,6.8,5.9,6.8,5.8c0,0,0,0,0,0V5.5z"/>
      </svg>
  );
}

export default FlagES;
