function PlumpyTournaments(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M22.466,6.299C21.899,5.485,20.971,5,19.983,5H19c0,0.698-0.027,1.36-0.068,2h1.052c0.334,0,0.649,0.165,0.843,0.442c0.104,0.15,0.259,0.459,0.118,0.842c-0.46,1.249-1.281,2.104-2.005,2.857c-0.294,0.306-0.58,0.606-0.83,0.918c-0.222,0.812-0.469,1.547-0.732,2.206C17.496,14.686,17.866,15,18.324,15c0.552,0,1-0.447,1-1c0-0.372,0.49-0.882,1.057-1.473c0.818-0.851,1.835-1.91,2.44-3.552C23.153,8.073,23.024,7.098,22.466,6.299z"
              fill="currentColor"/>
          <path
              d="M1.535,6.299C2.102,5.485,3.031,5,4.018,5h0.983c0,0.698,0.027,1.36,0.068,2H4.018C3.684,7,3.369,7.165,3.176,7.442c-0.104,0.15-0.259,0.459-0.118,0.842c0.46,1.249,1.281,2.104,2.005,2.857c0.294,0.306,0.58,0.606,0.83,0.918c0.222,0.812,0.469,1.547,0.732,2.206C6.506,14.686,6.136,15,5.678,15c-0.552,0-1-0.447-1-1c0-0.372-0.49-0.882-1.057-1.473c-0.818-0.851-1.835-1.91-2.44-3.552C0.849,8.073,0.978,7.098,1.535,6.299z"
              fill="currentColor"/>
          <path
              d="M17,3H7C5.895,3,5,3.895,5,5c0,7.603,2.639,11.737,3.904,13.283C9.28,18.743,9.845,19,10.44,19h3.121 c0.594,0,1.159-0.257,1.536-0.717C16.361,16.737,19,12.603,19,5C19,3.895,18.105,3,17,3z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M15.948,20.684l-0.333-1C15.478,19.275,15.096,19,14.666,19H9.334c-0.43,0-0.813,0.275-0.949,0.684l-0.333,1C7.836,21.331,8.318,22,9.001,22h5.998C15.682,22,16.164,21.331,15.948,20.684z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyTournaments;
