import {getHasCapability} from '../../utils/access';
import useUser from './useUser';
import useHasFeatureAccess from './useHasFeatureAccess';
import {FEATURES} from '../../constants/features';

function useHasCapability(capability, module) {

  const user = useUser();
  const userPermissions = user?.userObj?.adminRole?.permissions;

  const feature = FEATURES[module];
  const hasFeatureAccess = useHasFeatureAccess(module);

  if (feature && !hasFeatureAccess) return false;

  if (userPermissions) {
    return getHasCapability(userPermissions, capability, module);
  } else {
    return false;
  }
}

export default useHasCapability;
