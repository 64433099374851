import TournamentsList
  from '../../../components/Tournaments/List/TournamentsList';

function PageTournamentsListPast() {
  return (
      <TournamentsList type={'past'}/>
  );
}

export default PageTournamentsListPast;
