import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import useNavItems from '../Nav/useNavItems';
import LayoutMainNavVertical from '../Nav/LayoutMainNavVertical';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {PAGE_HEADER_HEIGHT} from '../../../constants/ui';
import LayoutMainHeaderClubSelect from '../LayoutMainHeaderClubSelect';
import PlumpyHelp from '../../../assets/icons/plumpy/PlumpyHelp';
import PlumpyLogout from '../../../assets/icons/plumpy/PlumpyLogout';
import {useUiSettings} from '../../../providers/ProvideUISettings';
import useLogout from '../../../hooks/access/useLogout';
import Scrollbar from '../../../components/_Global/Scroll/ScrollBar';
import {useTranslate} from '@tolgee/react';
import PlumpyChat from '../../../assets/icons/plumpy/PlumpyChat';

function LayoutMainHorizontalLayoutSidebar({role}) {

  const theme = useTheme();
  const {collapsed, setCollapsed} = useUiSettings();

  return (
      <Box
          sx={{
            height: '100vh',
            width: collapsed ? '90px' : '210px',
            borderRight: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: 0,
            zIndex: 4,
          }}
      >
        <Header collapsed={collapsed} setCollapsed={setCollapsed}/>
        <Nav role={role} collapsed={collapsed}/>
        <Footer collapsed={collapsed}/>

      </Box>
  );
}

function Header({collapsed, setCollapsed}) {

  return (
      <Box
          sx={{
            height: PAGE_HEADER_HEIGHT,
            px: 3.5,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            // justifyContent: collapsed && 'center'
          }}
      >
        <LayoutMainHeaderClubSelect style={'expanded'} collapsed={collapsed}/>
        <CollapseSideBarBtn
            collapsed={collapsed}
            setCollapsed={setCollapsed}
        />
      </Box>
  );
}

function Nav({role, collapsed}) {

  const navItems = useNavItems(role);
  const {setLayout} = useUiSettings();

  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'hidden',
          }}
      >
        <Scrollbar>
          <Box
              sx={{
                p: 2,
                pt: 0,
              }}
          >
            <LayoutMainNavVertical
                navItems={navItems}
                collapsed={collapsed}
                spacing={'compact'}
            />
          </Box>

        </Scrollbar>

      </Box>
  );

}

function Footer({collapsed}) {

  const {t} = useTranslate();
  const {logout} = useLogout();

  return (
      <Stack rowGap={.5} mt={'auto'} p={2}>
        <FooterActionBtn
            label={t(`navItems.link.contact`)}
            Icon={PlumpyChat}
            color={'primary.main'}
            collapsed={collapsed}
            id={'intercomBtn'}
            onClick={() => {
            }}
        />
        <FooterActionBtn
            label={'Logout'}
            Icon={PlumpyLogout}
            color={'primary.main'}
            collapsed={collapsed}
            onClick={() => {
              logout();
            }}
        />
      </Stack>
  );
}

function FooterActionBtn({
                           label,
                           Icon,
                           color,
                           className,
                           id,
                           collapsed,
                           onClick,
                         }) {

  return (
      <Tooltip
          title={collapsed && label}
          placement={'right'}
          arrow
      >
        <ButtonBase
            className={className}
            onClick={onClick}
            id={id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              borderRadius: '8px',
              columnGap: '16px',
              textAlign: 'left',
              p: '10px 16px',
              color: color,
              flexShrink: 0,
              'svg': {
                width: '24px',
                display: 'flex',
                flexShrink: 0,
              },
            }}
        >
          {
              Icon && <Icon/>
          }
          {
              !collapsed &&
              <Typography fontSize={'14px'} fontWeight={500} color={'inherit'} >
                {label}
              </Typography>
          }

        </ButtonBase>
      </Tooltip>
  );
}

function CollapseSideBarBtn({collapsed, setCollapsed}) {

  const theme = useTheme();

  return (
      <IconButton
          size={'small'}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          sx={{
            position: 'absolute',
            zIndex: 99,
            right: '-12px',
            border: `1px solid ${theme.palette.divider}`,
            width: '22px',
            height: '22px',
            backgroundColor: theme.palette.background.neutral,
            svg: {
              width: '18px',
            },
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
      >
        {
            !collapsed &&
            <ChevronLeft/>
        }
        {
            collapsed &&
            <ChevronRight/>
        }
      </IconButton>
  );
}

export default LayoutMainHorizontalLayoutSidebar;
