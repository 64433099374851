import {statusError, statusLoading, statusSuccess} from './status';
import {
  BOOKINGS_AVAILABLE_COURTS_GET_FAILURE,
  BOOKINGS_AVAILABLE_COURTS_GET_LOADING,
  BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS,
  BOOKINGS_BY_ID_GET_FAILURE,
  BOOKINGS_BY_ID_GET_LOADING,
  BOOKINGS_BY_ID_GET_SUCCESS,
  BOOKINGS_CANCEL_POST_FAILURE,
  BOOKINGS_CANCEL_POST_LOADING,
  BOOKINGS_CANCEL_POST_SUCCESS,
  BOOKINGS_CREATE_POST_FAILURE,
  BOOKINGS_CREATE_POST_LOADING,
  BOOKINGS_CREATE_POST_SUCCESS,
  BOOKINGS_CUSTOMERS_GET_FAILURE,
  BOOKINGS_CUSTOMERS_GET_LOADING,
  BOOKINGS_CUSTOMERS_GET_SUCCESS,
  BOOKINGS_EXTRAS_GET_FAILURE,
  BOOKINGS_EXTRAS_GET_LOADING,
  BOOKINGS_EXTRAS_GET_SUCCESS,
  BOOKINGS_LIST_GET_FAILURE,
  BOOKINGS_LIST_GET_LOADING,
  BOOKINGS_LIST_GET_SUCCESS,
  BOOKINGS_TYPES_GET_FAILURE,
  BOOKINGS_TYPES_GET_LOADING,
  BOOKINGS_TYPES_GET_SUCCESS,
} from '../constants';
import axios from 'axios';


export const bookingsByID_GET = (bookingID, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_BY_ID_GET_LOADING));
      const response = await axios.get(`bookings/${bookingID}`);
      dispatch(statusSuccess(BOOKINGS_BY_ID_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_BY_ID_GET_FAILURE, e));
    }
  };
};

export const bookingsCreate_POST = (body, onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_CREATE_POST_LOADING));
      const response = await axios.post('bookings/create', body);
      dispatch(statusSuccess(BOOKINGS_CREATE_POST_SUCCESS, response.data));
      onSuccess(response);
    } catch (e) {
      dispatch(statusError(BOOKINGS_CREATE_POST_FAILURE, e));
      onFail(e);
    }
  };
};

export const bookingsCheckin_POST = (
    bookingType, bookingId, user, checkedIn, onSuccess, onFail) => {
  return async () => {

    const body = {
      bookingType,
      bookingId,
      user,
      checkedIn,
    };

    try {
      const response = await axios.post(`bookings/checkin`, body);
      onSuccess(response);
    } catch (e) {
      onFail(e);
    }
  };
};

export const bookingsCancel_POST = (bookingId,body, onSuccess, onFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_CANCEL_POST_LOADING));
      const response = await axios.post(`bookings/cancel/${bookingId}`,body);
      dispatch(statusSuccess(BOOKINGS_CANCEL_POST_SUCCESS, response.data));
      onSuccess(response);
    } catch (e) {
      dispatch(statusError(BOOKINGS_CANCEL_POST_FAILURE, e));
      onFail(e);
    }
  };
};

export const bookingsList_GET = (
    clubId, booker, status, limit, pageNo, date, type, cbSuccess,
    cbFailure) => {
  return async (dispatch) => {
    try {
      const sortBy = 'date';
      const orderBy = 'DESC';
      dispatch(statusLoading(BOOKINGS_LIST_GET_LOADING));
      const response = await axios.get(
          `bookings/list?clubId=${clubId}&booker=${booker}&status=${status}&sortBy=${sortBy}&orderBy=${orderBy}&date=${date}&type=${type}`);
      dispatch(statusSuccess(BOOKINGS_LIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_LIST_GET_FAILURE, e));
    }
  };
};

export const bookingsListServerSide_GET = (
    clubId, booker, status, limit, pageNo,searchTerm, date, type,startDate, endDate, cbSuccess,
    cbFailure) => {
  return async (dispatch) => {
    try {
      const sortBy = 'date';
      const orderBy = 'DESC';
      dispatch(statusLoading(BOOKINGS_LIST_GET_LOADING));
      const response = await axios.get(
          `bookings/listAll?clubId=${clubId}&booker=${booker}&status=${status}&sortBy=${sortBy}&orderBy=${orderBy}&
          date=${date}&type=${type}&limit=${limit}&page=${pageNo}&searchTerm=${searchTerm}&startDate=${startDate}&endDate=${endDate}`);
      dispatch(statusSuccess(BOOKINGS_LIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_LIST_GET_FAILURE, e));
    }
  };
};

export const bookingsScheduler_GET = (
    clubId, date, type, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {

      dispatch(statusLoading(BOOKINGS_LIST_GET_LOADING));
      const response = await axios.get(
          `bookings/scheduler?&clubId=${clubId}&date=${date}&type=${type}`);
      dispatch(statusSuccess(BOOKINGS_LIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_LIST_GET_FAILURE, e));
    }
  };
};
export const bookingsTypes_GET = (cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_TYPES_GET_LOADING));
      const response = await axios.get(`bookings/types`);
      dispatch(statusSuccess(BOOKINGS_TYPES_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_TYPES_GET_FAILURE, e));
    }
  };
};

export const bookingsExtras_GET = (clubId,sport, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_EXTRAS_GET_LOADING));
      const response = await axios.get(`bookings/extras?clubId=${clubId}&sport=${sport}`);
      dispatch(statusSuccess(BOOKINGS_EXTRAS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_EXTRAS_GET_FAILURE, e));
    }
  };
};


export const bookingsAvailableCourts_POST = (body, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_AVAILABLE_COURTS_GET_LOADING));
      const response = await axios.post(`bookings/available-courts`, body);
      dispatch(
          statusSuccess(BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_AVAILABLE_COURTS_GET_FAILURE, e));
    }
  };
};

export const bookingsCustomers_GET = (searchTerm, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_CUSTOMERS_GET_LOADING));
      const response = await axios.post(
          `bookings/customers`,{searchTerm});
      dispatch(statusSuccess(BOOKINGS_CUSTOMERS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_CUSTOMERS_GET_FAILURE, e));
    }
  };
};

export const scheduleAvailableCourts_GET = (body, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(BOOKINGS_CUSTOMERS_GET_LOADING));
      const response = await axios.post(`bookings/schedule/available-courts`,
          body);
      dispatch(statusSuccess(BOOKINGS_CUSTOMERS_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(BOOKINGS_CUSTOMERS_GET_FAILURE, e));
    }
  };
};

export const bookingsNotify_POST = (
     bookingId, userId, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/bookings/notify`, {
        bookingId,
        userId,
      });
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};

export const bookingsRegularEditCourtsList = (
     body, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/bookings/update/available-courts`,body);
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
    }
  };
};


export const bookingsRegularEdit_PATCH=(payload,cbSucess,cbFailure)=>{

  return async(dispatch)=>{

    try{

      const response = await axios.patch(`/bookings/edit/${payload?.bookingId}`,payload)
      cbSucess(response)

    }catch(err){
      cbFailure(err)
    }
  }
}

export const bookingsRegularPaidAtCounter_PATCH=(payload,cbSucess,cbFailure)=>{

  return async(dispatch)=>{

    try{

      const response = await axios.patch(`/bookings/mark-paid-at-counter`,payload)
            cbSucess(response)

    }catch(err){
      cbFailure(err)
    }
  }
}

export const bookingsRegularPaidByWallet_PATCH=(payload,cbSucess,cbFailure)=>{

  return async(dispatch)=>{

    try{

      const response = await axios.patch(`/bookings/mark-paid-by-wallet`,payload)
            cbSucess(response)

    }catch(err){
      cbFailure(err)
    }
  }
}
