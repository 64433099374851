import {Box} from '@mui/material';
import useIsMobile from '../../../../hooks/ui/useIsMobile';

function PageCardContent({children,padding}) {

  const isMobile = useIsMobile();

  return (
      <Box
          sx={{
            width: '100%',
            padding: padding ?? (isMobile?2:4),
          }}
      >
        {children}
      </Box>
  );
}

export default PageCardContent;
