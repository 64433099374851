import {useTranslate} from '@tolgee/react';
import {EmojiEvents, ScoreboardOutlined, SearchOff} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';
import ChipRating from '../../_Global/Chips/ChipRating';
import TableCustom from '../../_Global/Table/TableCustom';
import {useTournamentsScoreboardContext} from './TournamentsScoreBoardProvider';
import {useMemo} from 'react';
import EmptyPlaceholder from '../../_Global/Empty/EmptyPlaceholder';

export function ScoreBoardScoreList() {

  const {t} = useTranslate();

  const {
    scores,
    loadingScores,
    searchTerm,
  } = useTournamentsScoreboardContext();

  const filtered = useMemo(() => {
    if (!scores) return;
    if (!searchTerm) return scores;
    return filterScores(scores, searchTerm);
  }, [searchTerm, scores]);

  function filterScores(scores, searchTerm) {

    if (!searchTerm) return scores;

    searchTerm = searchTerm.toLowerCase().trim();

    return scores?.filter(score => {

      if (!score?.team || !score?.team?.tournamentParticipants) return false;

      return score.team.tournamentParticipants.some(participant => {
        const user = participant.user;
        const firstName = user?.firstName?.trim().toLowerCase() || '';
        const lastName = user?.lastName?.trim().toLowerCase() || '';
        const fullName = `${firstName} ${lastName}`.trim();

        return (
            firstName.includes(searchTerm) ||
            lastName.includes(searchTerm) ||
            fullName.includes(searchTerm)
        );
      });

    });
  }

  const columns = [
    {
      flex: 0.5,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.ranking'),
      renderCell: (row) => {
        const ranking = row?.rank;
        if (typeof ranking === 'number' && ranking > 0) {
          return `${ranking}.`;
        }
      },
    },
    {
      flex: .3,
      align: 'center',
      label: '',
      renderCell: (row) => {
        const ranking = row?.rank;
        const isWinner = ranking === 1;
        if (isWinner) {
          return <EmojiEvents color={'warning'} fontSize={'large'}/>;
        }
      },
    },
    {
      flex: 2.5,
      label: t('tournaments.scoreboard.table.column.players'),
      renderCell: (row) => {

        const participants = row?.team?.tournamentParticipants;

        return (
            <Stack direction="row" spacing={3} alignItems={'flex-start'}>
              {
                participants?.map((p) => {

                  const user = p?.user;
                  const firstName = user?.firstName;
                  const lastName = user?.lastName;
                  const rating = user?.rating;

                  return (
                      <Stack alignItems={'flex-start'}>
                        <Typography>
                          {`${firstName || ''} ${lastName || ''}`}
                        </Typography>
                        {rating && <ChipRating rating={rating?.toFixed(2)} />}
                      </Stack>
                  );
                })
              }

            </Stack>
        );
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.wins'),
      renderCell: (row) => {
        return row?.matchesWon;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.draws'),
      renderCell: (row) => {
        return row?.matchesDraw;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.losses'),
      renderCell: (row) => {
        return row?.matchesLost;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.diff'),
      renderCell: (row) => {
        const scoreState = row.scoreStat;
        let color;
        let value;

        if (scoreState > 0) {
          color = 'success.main';
          value = `+${scoreState}`;
        }
        if (scoreState < 0) {
          color = 'error.main';
          value = `${scoreState}`;
        }
        if (scoreState === 0) {
          value = 0;
        }

        return (
            <Typography color={color}>
              {value}
            </Typography>
        );
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.points'),
      renderCell: (row) => {
        return (
            <Typography fontWeight={700} color={'primary.main'}>
              {row?.score}
            </Typography>
        );
      },
    },

  ];

  return (
      <TableCustom
          isSearching={!!searchTerm}
          columns={columns}
          rows={filtered}
          loading={loadingScores}
          getRowId={(row) => row.id}
          emptySlot={
            <EmptyPlaceholder
                icon={<ScoreboardOutlined color={'action'}/>}
                title={
                  <Stack
                      alignItems={'center'}
                      sx={{
                        maxWidth: '426px',
                        textAlign: 'center',
                      }}
                  >
                    <Typography variant={'body1'}>
                      No Scoreboard Yet
                    </Typography>
                    <Typography variant={'body2'}>
                      Once the tournament starts, you’ll see the scoreboard
                      details here.
                    </Typography>
                  </Stack>
                }
            />
          }
      />
  );

}
