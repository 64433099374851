import {Box, Stack} from '@mui/system';
import {useTranslate} from '@tolgee/react';
import {CircularProgress, Tooltip, Typography, useTheme} from '@mui/material';
import {
  useCourtsAvailabilityListContext,
} from './CourtsAvailabilityListProvider';
import {
  CheckCircleOutline,
  ErrorOutline,
  TopicRounded,
} from '@mui/icons-material';
import Scrollbar from '../_Global/Scroll/ScrollBar';
import dayjs from 'dayjs';
import {useEffect, useRef} from 'react';

function CourtsAvailabilityList() {
  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            rowGap: 2,
          }}
      >
        <CourtsAvailabilityListHeader/>
        <CourtsAvailabilityListTable/>
      </Box>
  );
}

function CourtsAvailabilityListHeader() {

  const {t} = useTranslate();

  let {
    courtsCount,
    conflictingCourtsCount,
  } = useCourtsAvailabilityListContext();

  let countValue;
  let conflictsValue;

  if (courtsCount !== undefined) {
    countValue = `${courtsCount}`.padStart(courtsCount > 0 ? 2 : 0, 0);
  }

  if (conflictingCourtsCount !== undefined) {
    conflictsValue = `${conflictingCourtsCount}`.padStart(
        conflictingCourtsCount > 0 ? 2 : 0, 0, 0);
  }

  return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography
            variant={'subtitle1'}
        >
          {t('event.totalCourts')} {countValue && `(${countValue})`}
        </Typography>
        <Typography
            variant={'subtitle1'}
            color={'error.main'}
        >
          {t('bookings.recurring.addEdit.slots.header.conflicts')} {conflictsValue &&
            `(${conflictsValue})`}
        </Typography>
      </Stack>
  );
}

function CourtsAvailabilityListTable() {

  const {t} = useTranslate();

  const tableHeaderRef = useRef(null);
  const tableBodyRef = useRef(null);

  const columns = [
    {
      label: t('courtsAvailabilityList.table.column.court'),
      flex: .8,
      minWidth: '100px',

      renderCell: (row) => {
        return row?.court?.name;
      },
    },
    {
      label: t('courtsAvailabilityList.table.column.date'),
      flex: 1,
      minWidth: '160px',
      renderCell: (row) => {
        return dayjs(row?.date, 'YYYY-MM-DD').format('ddd MMM D, YYYY');
      },
    },
    {
      label: t('courtsAvailabilityList.table.column.time'),
      flex: 1,
      minWidth: '180px',

      renderCell: (row) => {
        const sTime = row?.startTime;
        const eTime = row?.endTime;

        if (sTime && eTime) {
          return `${dayjs(sTime, 'HH:mm:ss').format('LT')} - ${dayjs(eTime,
              'HH:mm:ss').format('LT')}`;
        }

      },
    },
    {
      label: t('courtsAvailabilityList.table.column.status'),
      flex: 1,
      minWidth: '150px',

      renderCell: (row) => {
        return <CourtsAvailabilityListStatusCell slot={row}/>;
      },
    },
  ];

  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
          }}
      >
        <CourtsAvailabilityListTableHeader
            columns={columns}
            tableHeaderRef={tableHeaderRef}
        />
        <CourtsAvailabilityListTableBody
            columns={columns}
            tableBodyRef={tableBodyRef}
            tableHeaderRef={tableHeaderRef}
        />
      </Box>
  );
}

function CourtsAvailabilityListTableHeader({columns, tableHeaderRef}) {

  const {t} = useTranslate();
  const theme = useTheme();

  return (
      <Scrollbar
          ref={tableHeaderRef}
          autoHide={false}
          sx={{
            '.simplebar-track': {
              // display: 'none',
              opacity: 0,
            },
          }}
      >

        <Box
            sx={{
              display: 'flex',
              width: '100%',
              backgroundColor: theme.palette.grey[200],
              minWidth: 'max-content',

            }}
        >{
          columns?.map((column, index) => {
            return (

                <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: column.flex,
                      minWidth: column.minWidth,
                      py: 2,
                      px: 2,
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                >
                  {column.label}
                </Box>
            );
          })
        }
        </Box>
      </Scrollbar>
  );
}

function CourtsAvailabilityListTableBody({
                                           columns,
                                           tableBodyRef,
                                           tableHeaderRef,
                                         }) {

  const theme = useTheme();

  let {
    courts,
    isLoadingCourts,
    tableMaxHeight,
  } = useCourtsAvailabilityListContext();

  function handleScroll(sourceRef, targetRef) {
    const source = sourceRef.current;
    const target = targetRef.current;

    if (source && target) {
      target.scrollLeft = source.scrollLeft; // Synchronize horizontal scroll
    }
  }

  useEffect(() => {
    if (tableBodyRef?.current) {
      tableBodyRef.current.addEventListener('scroll', () => {
        handleScroll(tableBodyRef, tableHeaderRef);
      });
    }
  }, [isLoadingCourts, courts]);

  if (isLoadingCourts) {
    return <CourtsAvailabilityListTableLoading/>;
  }

  if (!isLoadingCourts && courts?.length === 0) {
    return <CourtsAvailabilityListTableEmpty/>;
  }

  return (
      <Scrollbar
          ref={tableBodyRef}
          autoHide={false}
          sx={{
            maxHeight: tableMaxHeight,
          }}
      >
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          {
            courts?.map((court, index) => {
              return (
                  <Box key={index} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    minWidth: 'max-content',
                  }}>
                    {
                      columns?.map((column, index) => {

                        const renderCell = column?.renderCell;

                        return (
                            <Box
                                key={index}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  minHeight: '60px',
                                  flex: column.flex,
                                  minWidth: column.minWidth,
                                  fontSize: '14px',
                                  px: 2,
                                  py: 1,
                                }}
                            >
                              {renderCell && renderCell(court)}
                            </Box>
                        );
                      })
                    }
                  </Box>
              );
            })
          }
        </Box>
      </Scrollbar>
  );
}

function CourtsAvailabilityListTableEmpty() {

  const theme = useTheme();
  const {t} = useTranslate();
  let {type} = useCourtsAvailabilityListContext();

  return (
      <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            paddingTop: '56px',
            paddingBottom: '80px',
          }}
      >
        <Stack spacing={1} alignItems={'center'}>
          <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                padding: '12px',
                borderRadius: '100%',
                backgroundColor: theme.palette.grey[300],
              }}
          >
            <TopicRounded color={'disabled'}/>
          </Box>
          {
              type === 'detail' &&
              <Typography
                  color={'text.secondary'}
              >
                No Court Bookings
              </Typography>
          }
          {
              type === 'create' &&
              <Typography
                  textAlign={'center'}
                  color={'text.secondary'}
                  maxWidth={'392px'}
              >
                {t('event.courtMessage')}
                <strong> {t('bookingAdd.court')}</strong>, <strong>{t(
                  'bookings.list.table.column.label.startDate')}
              </strong>, <strong>{t(
                  'addEvent.field.startTime')}</strong>, <strong>{t(
                  'bookings.list.table.column.label.endDate')}</strong> & <strong>{t(
                  'addEvent.field.endTime')}</strong>
              </Typography>
          }
        </Stack>

      </Box>
  );
}

function CourtsAvailabilityListTableLoading() {
  return <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        paddingTop: '56px',
        paddingBottom: '80px',
      }}
  >
    <CircularProgress/>
  </Box>;
}

function CourtsAvailabilityListStatusCell({slot}) {

  const theme = useTheme();
  const {status, msg} = slot;

  let label;

  if (status === 'fail') label = 'Conflict';
  if (status === 'pass') label = 'Available';

  return (
      <Tooltip
          disableHoverListener={status !== 'fail'}
          title={msg}
          placement={'top'}
          arrow
          sx={{
            cursor: status === 'fail' ? 'pointer' : '',
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: theme.palette.error.main,
                color: '#ffffff',
                fontSize: '13px',
                padding: '6px 12px',
                '.MuiTooltip-arrow': {
                  '&:before': {
                    backgroundColor: theme.palette.error.main,
                  },
                },
              },
            },
          }}
      >
        <Stack direction={'row'} spacing={.5} alignItems={'center'}>
          {status === 'fail' &&
              <ErrorOutline color={'error'} fontSize={'small'}/>}
          {status === 'pass' &&
              <CheckCircleOutline color={'success'} fontSize={'small'}/>}
          <Typography
              variant={'body2'}
              color={'text.primary'}
              textTransform={'capitalize'}
          >
            {label}
          </Typography>
        </Stack>
      </Tooltip>
  );
}

export default CourtsAvailabilityList;



