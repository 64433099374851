import {Box} from '@mui/material';

export function TabPanel({tabValue, index, sx={}, children}) {
  return (
      <Box
          hidden={tabValue !== index}
          sx={sx}
      >
        {tabValue === index && (
            <Box>
              {children}
            </Box>
        )}
      </Box>
  );
}
