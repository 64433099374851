import {file_DELETE} from '../../../../api/file-upload-delete';

export function uploadFileListHandleDelete(
    file, setIsDeleting, fieldName, getValues, setValue) {

  const isExisting = file?.existing;

  //Existing File flow

  if (isExisting) {
    //mark for deletion
    const files = getValues(fieldName);
    const idx = files.findIndex((i) => i.imageId === file.imageId);
    if (idx >= 0) {
      files[idx].markedForDelete = true;
      setValue(fieldName, files);
    }
  }

  // New File flow

  if (!isExisting) {

    //delete file on server
    setIsDeleting(true);
    file_DELETE(file.imageId, cbSuccess, cbFail);

    function cbSuccess(res) {
      // update field state
      const files = getValues(fieldName);
      const filtered = files?.filter((f) => f.imageId !== file.imageId);
      setValue(fieldName, filtered);
      setIsDeleting(false);
    }

    function cbFail(e) {
      setIsDeleting(false);
    }

  }
}

