function PlumpyChat(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
        <path
            d="M16,2H5C3.343,2,2,3.343,2,5v9c0,1.622,1.29,2.936,2.9,2.99l0.756,2.32c0.234,0.718,1.148,0.927,1.672,0.383L9.916,17H16 c1.657,0,3-1.343,3-3V5C19,3.343,17.657,2,16,2z"
            opacity=".35" fill="currentColor"/>
        <path
            d="M19,4h-0.184C18.928,4.314,19,4.647,19,5v9c0,1.657-1.343,3-3,3H9.916l-1.922,1.999C7.996,18.999,7.998,19,8,19h6.084l2.589,2.693c0.523,0.544,1.438,0.335,1.672-0.383l0.756-2.32C20.71,18.936,22,17.622,22,16V7C22,5.343,20.657,4,19,4z"
            fill="currentColor"/>
      </svg>
  );
}

export default PlumpyChat;
