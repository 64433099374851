import {alpha, Stack, useTheme} from '@mui/material';
import useIsMobile from '../../../../hooks/ui/useIsMobile';

function PageCardActions({children,marginTop,padding,width,mx,jc,position,bottom}) {

  const theme = useTheme()
  const isMobile = useIsMobile();

  return (
      <Stack
          direction={'row'}
          justifyContent={jc ?? 'space-between'}
          spacing={2}
          sx={{
            mt:marginTop ?? 3,
            marginX:mx,
            padding: padding ?? "24px 24px 24px 24px",
            // backgroundColor: isMobile? '#F2F9FE' :alpha(theme.palette.primary.main,.06),
            borderTop: `1px solid ${theme.palette.divider}`,
            width:width ?? 'auto',
            position:position,
            bottom:bottom,
            borderRadius:'0 0 12px 12px',
            zIndex:1
          }}
      >
        {children}
      </Stack>
  );
}

export default PageCardActions;
