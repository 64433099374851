import {Box, Stack, Typography, useMediaQuery} from '@mui/material';
import {PAGE_HEADER_HEIGHT} from '../../../constants/ui';

function PageHeader({
  breadcrumbsSlot: BreadcrumbsSlot,
  actionsSlot: ActionsSlot,
  description,
  breadcrumbsExtra:BreadcrumbsExtra
}) {

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
      <Box sx={{
        display:"flex",
        width:"100%",
        alignItems:"center",
        minHeight: PAGE_HEADER_HEIGHT,
        py:2,
      }}>
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{width:"100%"}}
            flexWrap={'wrap'}
            gap={1}
        >
          <Stack spacing={'4px'}>
            <Stack
            direction={'row'}
            flexWrap={'wrap'}
            gap={1}
            >
              {BreadcrumbsSlot}
              {BreadcrumbsExtra}
            </Stack>
            {
                description &&
                <Typography
                    color={'text.secondary'}
                >{description}</Typography>
            }
          </Stack>

          <Stack direction={'row'}
                 spacing={1}
                 sx={{ml: 'auto'}}
                 alignItems={'center'}>
            {ActionsSlot}
          </Stack>
        </Stack>
      </Box>
  );
}

export default PageHeader;
