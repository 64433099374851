function PlumpyDiscount(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
        <path fillRule="evenodd"
              d="M8.991,3.897c0.571-1.092,1.878-1.909,3.351-1.909s2.781,0.816,3.351,1.909c1.176-0.369,2.677-0.022,3.72,1.02c1.042,1.042,1.389,2.543,1.02,3.72c1.092,0.571,1.909,1.878,1.909,3.351s-0.816,2.78-1.909,3.351c0.369,1.176,0.022,2.677-1.02,3.72c-1.042,1.042-2.543,1.389-3.72,1.02c-0.571,1.092-1.878,1.909-3.351,1.909s-2.78-0.816-3.351-1.909c-1.176,0.369-2.678,0.022-3.72-1.02c-1.042-1.042-1.389-2.544-1.02-3.72c-1.092-0.571-1.909-1.878-1.909-3.351s0.816-2.781,1.909-3.351c-0.369-1.176-0.022-2.677,1.02-3.72C6.313,3.875,7.815,3.529,8.991,3.897z"
              clipRule="evenodd" opacity=".35" fill="currentColor"/>
        <path
            d="M8.999,17C8.79,17,8.58,16.935,8.4,16.8c-0.442-0.331-0.532-0.958-0.2-1.399l6-8c0.331-0.442,0.958-0.531,1.399-0.2c0.442,0.331,0.532,0.958,0.2,1.399l-6,8C9.604,16.862,9.304,17,8.999,17z"
            fill="currentColor"/>
        <circle cx="15.5" cy="14.5" r="1.5" fill="currentColor"/>
        <circle cx="8.5" cy="9.5" r="1.5" fill="currentColor"/>
      </svg>
  );
}

export default PlumpyDiscount;
