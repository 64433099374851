import {Controller, useFormContext} from 'react-hook-form';
import {
  APP_DEFAULT_INPUT_CHAR_LIMIT,
  APP_DEFAULT_INPUT_MULTI_LINE_CHAR_LIMIT,
} from '../../../constants/app-defaults';
import {InputAdornment, TextField} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';

function RHFPlainTextInput({
                             name,
                             label,
                             shrink = true,
                             placeholder,
                             requiredRules = {value: false},
                             validationRules = {},
                             variant = 'outlined' || 'filled' || 'standard',
                             size = 'medium' || 'small',
                             multiLine = false,
                             charLimit = APP_DEFAULT_INPUT_CHAR_LIMIT,
                             charLimitMultiLine = APP_DEFAULT_INPUT_MULTI_LINE_CHAR_LIMIT,
                             InputLabelProps = {},
                             InputProps,
                             rows,
                             error,
                             disabled = false,
                           }) {

  const {control, formState: {errors}} = useFormContext();

  return (
      <Controller
          control={control}
          name={name}
          rules={{
            required: requiredRules,
            validate: validationRules,
          }}
          render={({field: {ref, ...field}, fieldState, formState}) => {
            return (
                <TextField
                    inputRef={ref}
                    disabled={disabled}
                    fullWidth
                    multiline={multiLine}
                    rows={rows}
                    placeholder={placeholder}
                    name={name}
                    label={label}
                    variant={variant}
                    size={size}
                    error={error ?? !!errors[name]}
                    helperText={errors[name]?.message}
                    inputProps={{
                      maxLength: !multiLine ? charLimit : charLimitMultiLine,
                    }}
                    InputLabelProps={{
                      shrink: shrink ? shrink : undefined,
                      ...InputLabelProps,
                    }}
                    InputProps={{
                      ...InputProps,
                      endAdornment:<>
                        {
                          disabled &&
                            <InputAdornment position="end">
                              <LockOutlined color={'disabled'}/>
                            </InputAdornment>
                        }
                      </>
                    }}
                    {...field}


                />
            );
          }}
      />
  );

}

export default RHFPlainTextInput;

