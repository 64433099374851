import {useEffect, useState} from 'react';
import {
  recurBookingsValidate_POST,
} from '../../vendor/redux/actions/bookings-recurring';
import {useDispatch} from 'react-redux';

function useFetchCourts({
                          startDate,
                          endDate,
                          startTime,
                          endTime,
                          selectedCourts,
                        }) {

  const dispatch = useDispatch();

  const [courts, setCourts] = useState([]);
  const [isLoadingCourts, setIsLoadingCourts] = useState(false);

  function fetchCourts(
      startDate, endDate, startTime, endTime, selectedCourts) {

    setIsLoadingCourts(true);

    dispatch(recurBookingsValidate_POST(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        startTime.format('HH:mm:ss'),
        endTime.format('HH:mm:ss'),
        selectedCourts.join(','),
        'daily',
        startDate.format('dddd'),
        null,
        '',
        '',
        cbSuccess,
        cbFail,
    ));

    function cbSuccess(res) {
      const courts = res?.data?.data?.dates;
      setCourts(courts);
      setIsLoadingCourts(false);
    }

    function cbFail(e) {
      setCourts([]);
      setIsLoadingCourts(false);
    }
  }

  useEffect(() => {

    const timeOut = setTimeout(() => {
      const canFetchCourts = startDate && endDate && startTime && endTime &&
          selectedCourts?.length > 0;

      if (canFetchCourts) {
        fetchCourts(startDate, endDate, startTime, endTime, selectedCourts);
      } else {
        setIsLoadingCourts(false);
        setCourts([]);
      }

    }, 250);

    return () => clearTimeout(timeOut);

  }, [startDate, endDate, startTime, endTime, selectedCourts]);

  return {
    courts,
    isLoadingCourts,
  };

}

export default useFetchCourts;
