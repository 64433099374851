import {Stack} from '@mui/system';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import {SearchOff, SportsTennis} from '@mui/icons-material';
import EmptyPlaceholder from '../../_Global/Empty/EmptyPlaceholder';
import ChipRating from '../../_Global/Chips/ChipRating';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {tournamentsStartMatches_POST} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import {ERRORS} from '../../../constants/errors';

function TournamentsMatchesMatchListBody() {

  const {matches, loadingMatches, searchTerm} = useTournamentMatchesContext();

  const filtered = useMemo(() => {
    if (!matches) return;
    if (!searchTerm) return matches;
    return filterMatches(matches, searchTerm);
  }, [searchTerm, matches]);

  function filterMatches(matches, searchTerm) {

    if (!searchTerm) return matches;

    searchTerm = searchTerm.toLowerCase().trim();

    return matches.filter(match => {
      return [match.teamA, match.teamB].some(team => {
        return team.tournamentParticipants.some(participant => {
          const {firstName, lastName} = participant.user;
          const fullName = `${firstName} ${lastName}`.trim();

          return firstName.toLowerCase().includes(searchTerm) ||
              lastName.toLowerCase().includes(searchTerm) ||
              fullName.toLowerCase().includes(searchTerm);
        });
      });
    });
  }

  return (
      <Stack sx={{position: 'relative'}}>
        {loadingMatches && <LoadingIndicator/>}
        {loadingMatches && matches && <LoadingOverlay/>}
        {loadingMatches && !matches && <Box sx={{height: '400px'}}/>}
        {
            !loadingMatches && (!matches || matches?.length === 0) &&
            <MatchesEmpty/>
        }
        {
            matches?.length > 0 && filtered?.length > 0 &&
            <Stack divider={<Divider sx={{borderStyle: 'dashed'}}/>}>
              {
                filtered.map((match) => {
                  return <MatchItem key={match.id} match={match}/>;
                })
              }
            </Stack>
        }
        {
            matches?.length > 0 && filtered?.length === 0 &&
            <NoSearchResults/>
        }
      </Stack>

  );
}

export default TournamentsMatchesMatchListBody;

function MatchItem({match}) {

  return (
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
      >
        <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: 2,
              py: 4,
              px: 4,
            }}
        >
          {/*Left*/}
          <Box sx={{flex: 1, display: 'flex'}}>
            <MatchItemTeam team={match.teamA} className={'left'}/>
          </Box>

          {/*Center*/}
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
          >
            <MatchItemScores
                matchId={match.id}
                teamOneScore={match.teamAScore}
                teamTwoScore={match.teamBScore}
            />
          </Box>

          {/*Right*/}
          <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
          >
            <MatchItemTeam team={match.teamB} className={'right'}/>
          </Box>
        </Box>
      </Box>

  );
}

function MatchItemTeam({team, className}) {

  // give columns equal width
  useEffect(() => {

    const elements = document.querySelectorAll(`.${className}`);

    let maxWidth = 0;

    // Find the max width
    elements.forEach(el => {
      const width = el.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    // Apply the max width to all elements
    elements.forEach(el => {
      el.style.minWidth = `${maxWidth}px`;
    });

  }, []);

  return (
      <Stack
          className={className}
          direction="row"
          spacing={6}
      >
        {
          team.tournamentParticipants?.map((p, i) => {
            return <MatchItemTeamParticipant key={i} participant={p}/>;
          })
        }
      </Stack>
  );
}

function MatchItemTeamParticipant({participant}) {

  const user = participant?.user;

  const name = `${user?.firstName || ''} ${user?.lastName || ''}`;
  // const rating = user?.userRatings?.at(0);
  const rating = '2.33';

  return (
      <Stack alignItems="flex-start">
        <Typography fontWeight={600}>
          {name}
        </Typography>
        {
            rating && <ChipRating rating={rating}/>
        }
      </Stack>
  );
}

function MatchItemScores({matchId, teamOneScore, teamTwoScore}) {

  const {
    scoringFormat,
    loadingMatches,
    handleMatchScoreChange,
  } = useTournamentMatchesContext();

  const maxScore = (() => {
    let max = 32; //fallback
    if (typeof +scoringFormat === 'number') {
      max = +scoringFormat;
    }
    return max;
  })();

  const winner = (() => {
    if (typeof teamOneScore === 'number' && typeof teamTwoScore === 'number') {
      if (teamOneScore > teamTwoScore) return 'teamOne';
      if (teamOneScore < teamTwoScore) return 'teamTwo';
    }
  })();

  const options = useMemo(() => {
    return generateNumberArray(0, maxScore);
  }, []);

  function handleChangeTeamOneScore(score) {
    const remainder = maxScore - score;
    handleMatchScoreChange(matchId, score, remainder, teamOneScore,
        teamTwoScore);
  }

  function handleChangeTeamTwoScore(score) {
    const remainder = maxScore - score;
    handleMatchScoreChange(matchId, remainder, score, teamOneScore,
        teamTwoScore);
  }

  return (
      <Stack
          justifyContent="center"
          direction="row"
          spacing={1}
          alignItems="center"
      >
        <MatchItemScoresScoreInput
            value={teamOneScore}
            onChange={handleChangeTeamOneScore}
            options={options}
            isWinner={winner === 'teamOne'}
            disabled={loadingMatches}
        />
        <Typography color={'text.secondary'}>–</Typography>
        <MatchItemScoresScoreInput
            value={teamTwoScore}
            onChange={handleChangeTeamTwoScore}
            options={options}
            isWinner={winner === 'teamTwo'}
            disabled={loadingMatches}
        />
      </Stack>
  );
}

function MatchItemScoresScoreInput({
                                     value,
                                     onChange,
                                     options,
                                     isWinner,
                                     disabled,
                                   }) {

  const theme = useTheme();

  return (
      <Autocomplete
          value={value}
          disableClearable
          disabled={disabled}
          autoHighlight
          options={options}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          getOptionLabel={(option) => String(option)}
          sx={{
            '.MuiAutocomplete-popupIndicator': {
              display: 'none',
            },
          }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      textAlign: 'center',
                    },
                    endAdornment: null,
                  }}
                  inputProps={{
                    ...(params.inputProps),
                    style: {
                      ...params.inputProps.style,
                      textAlign: 'center',
                    },
                  }}
                  onInput={(event) => {
                    //allow only numeric value
                    event.target.value = event.target.value.replace(/\D/g, '');
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      textAlign: 'center',
                      height: '48px',
                      padding: '0px 16px !important',
                      backgroundColor: isWinner ?
                          alpha(theme.palette.success.main, .1) :
                          theme.palette.grey[100], // Background inside the input
                    },
                  }}
              />
          )}
          renderOption={(props, option, index) => (
              <Box
                  {...props}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center !important',
                    alignItems: 'center !important',
                    margin: 0.5,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    px: 1,
                    py: 2,
                    backgroundColor: alpha(theme.palette.success.main, .1),
                    color: theme.palette.success.dark,
                    '&:hover, &.Mui-focused': {
                      outline: `1px solid ${theme.palette.success.dark}`,
                      backgroundColor: `${alpha(theme.palette.success.main,
                          .1)} !important`,
                    },
                    '&[aria-selected=true]': {
                      backgroundColor: `${theme.palette.success.main} !important`,
                      color: '#fff',
                    },
                  }}
              >
                {option}
              </Box>
          )}

          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8], // Adds 10px space between input and dropdown
                  },
                },
              ],
            },
            paper: {
              sx: {
                boxShadow: 5,
                width: '400px',
                border: `1px solid ${theme.palette.divider}`,
                '.MuiAutocomplete-listbox': {
                  padding: '16px',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)', // 5 items per row
                  gap: .75,
                },
              },
            },
          }}

      />
  );

}

function LoadingIndicator() {
  return (<LinearProgress sx={{
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 2,
  }}/>);
}

function LoadingOverlay() {
  return (
      <Box sx={{
        pointerEvents: 'none',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha('#fff', .6),
      }}/>
  );
}

function MatchesEmpty() {

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();

  const {
    tournamentId,
    selectedCategory,
    fetchRoundsAndMatches,
  } = useTournamentMatchesContext();

  const [showStartTournamentConfirm, setShowStartTournamentConfirm] = useState(
      false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleStartTournament() {

    setIsSubmitting(true);

    tournamentsStartMatches_POST({
      tournamentId,
      categoryId: null,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      fetchRoundsAndMatches(tournamentId, selectedCategory?.id);
      setShowStartTournamentConfirm(false);
      setIsSubmitting(false);
    }

    function cbFail(e) {
      const errorMsg = e?.response?.data?.data?.error;
      enqueueSnackbar(t(errorMsg || ERRORS.NETWORK_ERROR), {variant: 'error'});
      setIsSubmitting(false);
    }

  }

  return (
      <Box
          sx={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <EmptyPlaceholder
            icon={<SportsTennis color={'action'}/>}
            title={
              <Stack
                  alignItems={'center'}
                  sx={{
                    maxWidth: '426px',
                    textAlign: 'center',
                  }}
              >
                <Typography variant={'body1'}>
                  No Matches Yet
                </Typography>
                <Typography variant={'body2'}>
                  Once the tournament starts, you’ll see tournament rounds and
                  matches details here.
                </Typography>
              </Stack>
            }
            actionSlot={
              <Button
                  variant={'contained'}
                  size={'medium'}
                  onClick={() => {
                    setShowStartTournamentConfirm(true);
                  }}
              >
                Start Tournament
              </Button>
            }
        />
        <DialogConfirmation
            variant={'primary'}
            isOpen={showStartTournamentConfirm}
            isSubmitting={isSubmitting}
            dialogTitle={'Confirm Start Tournament'}
            dialogContent={'Are you sure you want to start the tournament?'}
            closeButtonText={'Cancel'}
            confirmButtonLabel={'Start Tournament'}
            handleClose={() => {
              setShowStartTournamentConfirm(false);
            }}
            handleConfirm={handleStartTournament}
        />
      </Box>
  );
}

function NoSearchResults() {
  return (
      <Box
          sx={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <EmptyPlaceholder
            icon={<SearchOff color={'action'}/>}
            title={'No Search Results'}
        />
      </Box>
  );
}

function generateNumberArray(min, max) {
  return Array.from({length: max - min + 1}, (_, i) => min + i);
}

