import TournamentsScoreBoard
  from '../../../components/Tournaments/ScoreBoard/TournamentsScoreBoard';

function PageTournamentsDetailScoreBoard({tournamentDetails}) {

  const categories = tournamentDetails?.tournamentCategories;
  const tournamentId = tournamentDetails?.id;

  return (
      <TournamentsScoreBoard
          categories={categories}
          tournamentId={tournamentId}
      />
  );
}

export default PageTournamentsDetailScoreBoard;
