import {Skeleton, Stack, Typography} from '@mui/material';

function PageSectionTitle({
                            text,
                            titleMeta,
                            isLoading = false,
                            varient = 'h6',
                            mb = '24px',
                          }) {
  return (
      <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          spacing={2}
          mb={mb}
      >
        <Typography variant={varient} textTransform={'capitalize'}>
          {isLoading && <Skeleton width={'200px'}/>}
          {!isLoading && text}
        </Typography>
        {titleMeta}
      </Stack>

  );
}

export default PageSectionTitle;
