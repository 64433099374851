export function getSliderMarks(min, max, markStep, labelSuffix) {
  const marks = [];

  for (let value = min; value <= max; value += markStep) {
    marks.push(
        {value, label: `${value} ${labelSuffix || ''}`});
  }
  return marks;
}

export function getSliderMinMax() {

}
