import {
  DevTools,
  FormatSimple,
  Tolgee,
  TolgeeProvider as Provider,
} from '@tolgee/react';
import useDefaults from '../hooks/defaults/useDefaults';
import {APP_DEFAULT_LOCALE} from '../constants/app-defaults';
import {useMemo} from 'react';
import {getIsLocal} from '../utils/environments';

function ProviderTolgee({children}) {

  const {defaultLocale} = useDefaults();
  const localStorageLocale = JSON.parse(localStorage.getItem('locale'));

  const isLocal = getIsLocal();

  const tolgee = useMemo(() => {

    const options = {
      availableLanguages: ['en', 'nl','de','es','tr'],
      defaultLanguage: localStorageLocale || defaultLocale ||
          APP_DEFAULT_LOCALE,
      defaultNs: 'meet-translation',
      apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
      staticData:{
        'en:meet-translation': ()=>import('../i18n/meet-translation/en.json'),
        'nl:meet-translation': ()=>import('../i18n/meet-translation/nl.json'),
        'de:meet-translation': ()=>import('../i18n/meet-translation/de.json'),
        'es:meet-translation': ()=>import('../i18n/meet-translation/es.json'),
        'tr:meet-translation': ()=>import('../i18n/meet-translation/tr.json'),
      }
    };

    if (isLocal) {
      options.apiKey = process.env.REACT_APP_TOLGEE_API_KEY;
    }

    return Tolgee().
        use(DevTools()).
        use(FormatSimple()).
        init(options);
  }, []);

  return <Provider tolgee={tolgee}>
    {children}
  </Provider>;
}

export default ProviderTolgee;
