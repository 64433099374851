import {Divider, Stack} from '@mui/material';
import TournamentsMatchesMatchListHeader
  from './TournamentsMatchesMatchListHeader';
import TournamentsMatchesMatchListBody from './TournamentsMatchesMatchListBody';

function TournamentsMatchesMatchList() {
  return (
      <Stack
          divider={<Divider/>}
          sx={{
            backgroundColor: 'white',
            width: '100%',
          }}
      >
        <TournamentsMatchesMatchListHeader/>
        <TournamentsMatchesMatchListBody/>
      </Stack>
  );
}

export default TournamentsMatchesMatchList;
