import {useController, useFormContext} from 'react-hook-form';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

function RHFRadioGroup({
                         name,
                         rules,
                         options,
                         disabled,
                         row = true,
                       }) {

  const {control} = useFormContext();

  const {field} = useController({
    name,
    control,
    rules,
  });

  return (
      <FormControl>
        <RadioGroup
            row={row}
            {...field}
        >
          {
            options?.map((option, index) => {
              return (
                  <FormControlLabel
                      key={index}
                      value={option.value}
                      label={option.label}
                      control={<Radio disabled={disabled} />}
                  />
              );
            })}
        </RadioGroup>
      </FormControl>
  );

}

export default RHFRadioGroup;
