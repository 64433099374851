import {Box} from '@mui/system';
import PageHeader from '../../../components/_Global/Page/PageHeader';
import {useState} from 'react';
import {TabPanel} from '../../../components/_Global/Tabs/TabPanel';
import PageTournamentsListActive from './PageTournamentsListActive';
import PageTournamentsListPast from './PageTournamentsListPast';
import {Button, Tab, Tabs} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import PageCard from '../../../components/_Global/Page/PageCard/PageCard';
import PageCardToolbar
  from '../../../components/_Global/Page/PageCard/PageCardToolbar';
import BreadcrumbsStatic
  from '../../../components/_Global/Breadcrumbs/BreadcrumbsStatic';

function PageTournamentsList({initialTabValue = 0}) {

  const {t} = useTranslate();
  const navigateTo = useNavigate();

  const [tabValue, setTabValue] = useState(initialTabValue);

  const tabs = [
    {
      label: t('tournaments.list.active'),
      ViewComponent: <PageTournamentsListActive/>,
    },
    {
      label: t('tournaments.list.past'),
      ViewComponent: <PageTournamentsListPast/>,
    },
  ];

  const actions = [
    {
      label: t('tournaments.list.actions.createTournament'),
      color: 'inherit',
      variant: 'contained',
      enabled: true,
      startIcon: <Add/>,
      onClick: () => {
        navigateTo('/tournaments/create');
      },
    },
  ];

  const breadcrumbs = [
    {
      label: t('tournaments.list'),
    }
  ]

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        return navigateTo('/tournaments/active');
      case 1:
        return navigateTo('/tournaments/past');
    }
  };

  return (
      <Box>
        <PageHeader
            breadcrumbsSlot={
             <BreadcrumbsStatic options={breadcrumbs} />
            }
            actionsSlot={
              actions?.map(
                  (
                      {label, color, variant, startIcon, enabled, onClick},
                      index) => {
                    if (!enabled) return;
                    return (
                        <Button
                            startIcon={startIcon}
                            key={index}
                            color={color}
                            variant={variant}
                            onClick={onClick}
                        >
                          {label}
                        </Button>
                    );
                  })
            }
        />
        <PageCard>
          <PageCardToolbar>
            <Tabs value={tabValue} onChange={handleTabChange}>
              {
                tabs?.map((tab, index) => (
                    <Tab key={index} label={tab.label}/>
                ))
              }
            </Tabs>
          </PageCardToolbar>
          {
            tabs?.map((tab, index) => (
                <TabPanel key={index} index={index} tabValue={tabValue}>
                  {tab.ViewComponent}
                </TabPanel>
            ))
          }
        </PageCard>

      </Box>
  );
}

export default PageTournamentsList;
