import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import {Box, Tab, Tabs} from '@mui/material';
import {useTranslate} from '@tolgee/react';

function TournamentsMatchesCategorySelector() {

  const {
    categories,
    selectedCategory,
    handleSelectCategory,
    loadingMatches,
    loadingRounds,
  } = useTournamentMatchesContext();

  const {t} = useTranslate();

  const selectedCategoryIdx = categories?.findIndex(
      (c) => c.id === selectedCategory.id);

  function handleOnChange(_, newIndex) {
    const category = categories[newIndex];
    handleSelectCategory(category);
  }

  return (
      <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
      >
        <Tabs
            value={selectedCategoryIdx}
            variant={'scrollable'}
            onChange={handleOnChange}
            TabIndicatorProps={{
              sx: {
                background: '#fff',
                height: '100%',
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: 6,
              },
            }}
            sx={{
              '& .MuiTab-root': {
                zIndex: 1,
                margin: 0,
              },
            }}
        >
          {
            categories?.map((category, index) => {
              return (
                  <Tab
                      disabled={loadingMatches || loadingRounds}
                      key={index}
                      label={t(
                          `tournaments.types.categories.${category.type}`)}
                      sx={{
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        p: 2,
                        opacity:"1 !important",
                      }}
                  />
              );
            })
          }

        </Tabs>
      </Box>
  );

}

export default TournamentsMatchesCategorySelector;
