import {useController, useFormContext} from 'react-hook-form';
import {useState} from 'react';
import {InputAdornment, Popover, TextField, useTheme} from '@mui/material';
import {CalendarTodayOutlined, LockOutlined} from '@mui/icons-material';
import {DateCalendar} from '@mui/x-date-pickers';

function RHFDatePicker({
                         name,
                         label,
                         disablePast = true,
                         disabled = false,
                         readOnly = false,
                         minDate,
                         maxDate,
                         variant = 'outlined',
                         size = 'medium',
                         rules,
                         valueFormat = 'dddd, D MMMM, YYYY',
                         onChangeCallback = (newDate) => {
                         },
                       }) {

  const theme = useTheme();
  const {control} = useFormContext();

  const {
    field: {onChange, value, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
    rules,
  });

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const IS_OPEN_POPOVER = Boolean(popoverAnchorEl);

  function handleOpenPopover(event) {
    if (disabled) return;
    setPopoverAnchorEl(event.currentTarget);
  }

  function handleClosePopOver() {
    setPopoverAnchorEl(null);
  }

  function handleChangeDate(date) {
    onChangeCallback(date);
    onChange(date);
    handleClosePopOver();
  }

  return (
      <>
        <TextField
            inputRef={ref}
            fullWidth
            variant={variant}
            onClick={handleOpenPopover}
            label={label}
            disabled={disabled}
            size={size}
            error={!!error}
            helperText={error?.message}
            value={value ? value?.format(valueFormat) : ''}
            sx={{
              pointerEvents: (readOnly || disabled) ? 'none' : 'initial',
              '.MuiOutlinedInput-notchedOutline': {
                ...(IS_OPEN_POPOVER) && {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px',
                },
              },
            }}
            InputLabelProps={{
              shrink: !!value,
            }}
            InputProps={{
              readOnly: true,
              endAdornment: <>
                <InputAdornment position={'end'}>
                  {
                      !disabled &&
                      <CalendarTodayOutlined color={'action'}/>
                  }
                  {
                      disabled &&
                      <LockOutlined color={'disabled'}/>
                  }
                </InputAdornment>
              </>,
              sx: {
                '.MuiInputBase-input': {
                  cursor: 'pointer',
                },
              },
            }}
        />
        {
            !readOnly && !disabled &&
            <Popover
                open={IS_OPEN_POPOVER}
                anchorEl={popoverAnchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
            >
              <DateCalendar
                  onChange={handleChangeDate}
                  value={value}
                  disablePast={disablePast}
                  maxDate={maxDate || null}
                  minDate={minDate || null}
                  disabled={disabled}
              />
            </Popover>
        }
      </>
  );

}

export default RHFDatePicker;
