
const transactionsLinks={
    'order':{url:'/bookings/reservation/detail',requireState:false},
    'package':{url:'/packages/detail/',requireState:false},
    'offer':{url:'/offers/detail',requireState:false},
    'event':{url:'/events/detail/',requireState:true},
    'recurr':{url:'/recurring/recurrence-detail/'},
    'Wallet Top Up':{url:`/wallets/transactions/detail`},
    'open_match':{url:`/openMatches/single/detail`},
    'training':{url:`/training/detail/`},
}

export const getLinksStates=(value)=>{
    
    const type = value?.type

    let obj = {
        
        'event':{
            name: value?.eventBooking?.name,
            id: getId(value,type),
            status: value?.eventBooking?.status,
            type:''
        },
        'training':{
            id: value?.trainingId,
            statue:value?.status
        }
    }

    const toReturn = obj[type]
 
    return toReturn
}

const linksParams={
    'order':['booking-id'],
    'offer':['id'],
    'Wallet Top Up':['id'],
    'open_match':['id'],
}

export const getId =(row,type)=>{

    let obj={
        'package':row?.userPackage?.id,
        'event':row?.eventBooking?.id,
        'offer':row?.walletOffer?.id,
        'recurr':row?.recurrBooking?.id,
        'order':row?.bookingId,
        'Wallet Top Up':row?.topupId,
        'open_match':row?.openMatch?.id,
        'training': row?.trainingId
    }

    return obj[type]

}

export const getTransactionLink=(row,type)=>{

    const id = getId(row,type)
    const linkParam = linksParams[type]
    const link = transactionsLinks[type]

    if(type=='package'){

        return `/packages/detail/${row?.userPackage?.packageId}/registration?viewDefault=true&defaultId=${id}`

    }

    let URL = `${link?.url}${linkParam? `?${linkParam[0]}=${id}&reRoute=true`:`${id}` ?? ''}`
    
    return URL 

}