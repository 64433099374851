import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {
  tournamentsMatchesList_GET,
  tournamentsMatchScoreUpdate_PATCH,
  tournamentsRoundsList_GET,
} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';

const TournamentsMatchesContext = createContext();

function TournamentMatchesProvider({
                                     tournamentId,
                                     categories,
                                     scoringFormat,
                                     children,
                                   }) {

  const searchRef = useRef();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const [loadingRounds, setLoadingRounds] = useState(true);
  const [rounds, setRounds] = useState();
  const [selectedRound, setSelectedRound] = useState();

  const [loadingMatches, setLoadingMatches] = useState(true);
  const [matches, setMatches] = useState();

  const [searchTerm, setSearchTerm] = useState('');

  function handleSelectRound(newRound) {
    setSelectedRound(newRound);
    handleClearSearch();
    fetchMatches(tournamentId, selectedCategory.id, newRound.id);
  }

  function handleSelectCategory(newCategory) {
    setSelectedCategory(newCategory);
    handleClearSearch();
    fetchRoundsAndMatches(tournamentId, newCategory.id);
  }

  function handleMatchScoreChange(
      matchId,
      newTeamOneScore,
      newTeamTwoScore,
      originalTeamOneScore,
      originalTeamTwoScore,
  ) {

    // Update Client State
    setMatches((prevState) => {
      const clone = [...prevState];
      const matchIdx = clone.findIndex(match => match.id === matchId);
      const match = clone[matchIdx];
      match.teamAScore = newTeamOneScore;
      match.teamBScore = newTeamTwoScore;
      return clone;
    });

    //Update Server State
    tournamentsMatchScoreUpdate_PATCH({
      matchId: matchId,
      teamAScore: newTeamOneScore,
      teamBScore: newTeamTwoScore,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
    }

    function cbFail(e) {
      enqueueSnackbar('Score update failed', {variant: 'error'});
      // Reset Client State
      setMatches((prevState) => {
        const clone = [...prevState];
        const matchIdx = clone.findIndex(match => match.id === matchId);
        const match = clone[matchIdx];
        match.teamAScore = originalTeamOneScore;
        match.teamBScore = originalTeamTwoScore;
        return clone;
      });

    }

  }

  function handleClearSearch() {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchTerm('');
  }

  function fetchRoundsAndMatches(tournamentId, categoryId) {

    setLoadingRounds(true);
    setLoadingMatches(true);

    tournamentsRoundsList_GET({
      tournamentId,
      categoryId,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {

      const rounds = res;
      let selectedRound;

      const activeRound = rounds?.find((round) => round.status === 'active');

      if (activeRound) {
        selectedRound = activeRound;
      }

      if (!activeRound) {
        const firstRound = rounds?.at(0);
        if (firstRound) {
          selectedRound = firstRound;
        }
      }

      if (!selectedRound) {
        setRounds(null);
        setMatches(null);
        setSelectedRound(null);
        setLoadingRounds(false);
        setLoadingMatches(false);
      }

      if (selectedRound) {

        setSelectedRound(selectedRound);
        setRounds(res);
        setLoadingRounds(false);

        tournamentsMatchesList_GET({
          tournamentId,
          categoryId,
          roundId: selectedRound?.id,
          cbSuccess: (res) => {
            setMatches(res);
            setLoadingMatches(false);
          },
          cbFail: (error) => {
            setMatches(null);
            setLoadingMatches(false);
          },
        });

      }

    }

    function cbFail(e) {
      setRounds(null);
      setMatches(null);
      setSelectedRound(null);
      setLoadingRounds(false);
      setLoadingMatches(false);
    }
  }

  function fetchMatches(tournamentId, categoryId, roundId) {

    setLoadingMatches(true);

    tournamentsMatchesList_GET({
      tournamentId,
      categoryId,
      roundId,
      cbSuccess: (res) => {
        setMatches(res);
        setLoadingMatches(false);
      },
      cbFail: (error) => {
        setMatches(null);
        setLoadingMatches(false);
      },
    });
  }

  useEffect(() => {
    fetchRoundsAndMatches(tournamentId, selectedCategory?.id);
  }, []);

  return (
      <TournamentsMatchesContext.Provider
          value={{
            tournamentId,
            scoringFormat,
            fetchMatches,
            fetchRoundsAndMatches,
            loadingMatches,
            matches,
            handleMatchScoreChange,
            loadingRounds,
            rounds,
            selectedRound,
            handleSelectRound,
            categories,
            selectedCategory,
            handleSelectCategory,
            searchTerm,
            setSearchTerm,
            searchRef,
            handleClearSearch,
          }}
      >
        {children}
      </TournamentsMatchesContext.Provider>
  );
}

export default TournamentMatchesProvider;

export function useTournamentMatchesContext() {
  const {
    tournamentId,
    scoringFormat,
    fetchMatches,
    fetchRoundsAndMatches,
    loadingMatches,
    matches,
    handleMatchScoreChange,
    loadingRounds,
    rounds,
    selectedRound,
    handleSelectRound,
    categories,
    selectedCategory,
    handleSelectCategory,
    searchTerm,
    setSearchTerm,
    searchRef,
    handleClearSearch,
  } = useContext(TournamentsMatchesContext);

  return {
    tournamentId,
    scoringFormat,
    fetchMatches,
    fetchRoundsAndMatches,
    loadingMatches,
    matches,
    handleMatchScoreChange,
    loadingRounds,
    rounds,
    selectedRound,
    handleSelectRound,
    categories,
    selectedCategory,
    handleSelectCategory,
    searchTerm,
    setSearchTerm,
    searchRef,
    handleClearSearch,
  };
}
