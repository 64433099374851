import {Box, Collapse, IconButton, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {useState} from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';

function RegistrationsSection({
                                label,
                                count,
                                action,
                                defaultCollapsed = true,
                                children,
                              }) {

  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  return (
      <Box>
        <Stack direction="row" justifyContent="space-between"
               alignItems="center" spacing={1} mb={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
                size={'small'}
                onClick={() => {
                  setCollapsed((prev) => !prev);
                }}
            >
              {collapsed && <ExpandLess/>}
              {!collapsed && <ExpandMore/>}
            </IconButton>
            <Typography>
              {`${label} ${typeof count === 'number' ? `(${count})` : ''}`}
            </Typography>
          </Stack>
          {action}
        </Stack>
        <Collapse in={collapsed}>
          <Box mb={3}>
            {children}
          </Box>
        </Collapse>
      </Box>
  );
}

export default RegistrationsSection;
