import {useController, useFormContext} from 'react-hook-form';
import {useRef, useState} from 'react';
import {InputAdornment, Popover, TextField, useTheme} from '@mui/material';
import {AccessTimeOutlined, LockOutlined} from '@mui/icons-material';
import {DigitalClock} from '@mui/x-date-pickers';

function RHFTimePicker({
                         name,
                         label,
                         disablePast = false,
                         fullWidth = true,
                         disabled = false,
                         readOnly = false,
                         minTime,
                         maxTime,
                         variant = 'outlined',
                         size = 'medium',
                         rules,
                         valueFormat = 'h:mm A',
                         timeStep = 30,
                         onChangeCallback = (newTime) => {
                         },
                         ...props
                       }) {

  const theme = useTheme();
  const {control} = useFormContext();

  const {
    field: {onChange, value, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
    rules,
  });

  const inputRef = useRef(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const IS_OPEN_POPOVER = Boolean(popoverAnchorEl);

  function handleOpenPopover(event) {
    if (disabled) return;
    setPopoverAnchorEl(event.currentTarget);
  }

  function handleClosePopOver() {
    setPopoverAnchorEl(null);
  }

  function handleChangeTime(newDate) {
    onChangeCallback(newDate);
    onChange(newDate);
    handleClosePopOver();
  }

  return (
      <>
        <TextField
            ref={inputRef}
            inputRef={ref}
            fullWidth
            variant={variant}
            onClick={handleOpenPopover}
            label={label}
            disabled={disabled}
            size={size}
            error={!!error}
            helperText={error?.message}
            value={value ? value?.format(valueFormat) : ''}
            sx={{
              pointerEvents: (readOnly || disabled) ? 'none' : 'initial',
              '.MuiOutlinedInput-notchedOutline': {
                ...(IS_OPEN_POPOVER) && {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px',
                },
              },
            }}
            InputLabelProps={{
              shrink: !!value,
            }}
            InputProps={{
              readOnly: true,
              endAdornment:
                  <>
                    <InputAdornment position={'end'}>
                      {
                          !disabled &&
                          <AccessTimeOutlined color={'action'}/>
                      }
                      {
                          disabled &&
                          <LockOutlined color={'disabled'}/>
                      }
                    </InputAdornment>
                  </>,
              sx: {
                '.MuiInputBase-input': {
                  cursor: 'pointer',
                },
              },
            }}
        />
        {
            !readOnly && !disabled &&
            <Popover
                open={IS_OPEN_POPOVER}
                anchorEl={popoverAnchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                slotProps={{
                  paper: {
                    sx: {
                      width: inputRef?.current?.offsetWidth,
                    },
                  },
                }}
            >
              <DigitalClock
                  timeStep={timeStep}
                  value={value}
                  onChange={handleChangeTime}
                  minTime={minTime}
                  maxTime={maxTime}
                  skipDisabled={true}
                  disablePast={disablePast}
                  sx={{
                    width: '100%',
                    '& .MuiDigitalClock-root': {
                      width: '100%',
                    },
                  }}
              />
            </Popover>
        }
      </>
  );

}

export default RHFTimePicker;
