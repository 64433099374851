import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {setSelectedClub} from '../../vendor/redux/actions/selected-club';
import {useNavigate} from 'react-router-dom';
import {ExpandLess, ExpandMore, UnfoldMore} from '@mui/icons-material';
import useUser from '../../hooks/access/useUser';
import useSwitcherClubs from '../../hooks/clubs/useSwitcherClubs';
import {useSelectedClub} from '../../hooks/clubs/useSelectedClub';
import axios from 'axios';
import {useState} from 'react';
import ProfileImageViewer
  from '../../components/_Global/Profile/ProfileImageViewer';

function LayoutMainHeaderClubSelect({
                                      size = 'expanded' || 'compact',
                                      color = 'default' || 'inverted',
                                      collapsed,
                                    }) {

  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const user = useUser();
  const switcherClubs = useSwitcherClubs();

  const selectedClub = useSelectedClub();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const isAdmin = user?.userObj?.role !== 'club_staff';

  const isInverted = color === 'inverted';
  const isCompact = size === 'compact';

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  function handleChangeClub(newClub) {

    if (newClub.clubId !== selectedClub.id) {

      const normalized = {
        id: newClub.clubId,
        timezone: newClub.addresses[0].timezone,
        name: newClub.name,
        imageUrl: newClub.imageUrl,
        email: newClub?.email,
      };

      axios.defaults.headers.common['x-clubos-club'] = normalized.id;
      dispatch(setSelectedClub(normalized));
      navigateTo('/schedule');
    }

    setAnchorEl(null);

  }

  if (selectedClub && switcherClubs?.length > 0) {
    return (
        <>
          <Stack
              direction={'row'}
              columnGap={isCompact ? .5 : 1}
              alignItems={'center'}
              onClick={handleOpenPopover}
              sx={{
                color: isInverted ? '#fff' : 'text.secondary',
                'svg': {
                  width: isCompact ? '16px' : '18px',
                },
                ...(isAdmin && switcherClubs?.length > 1) && {
                  cursor: 'pointer',
                },
              }}
          >
            <Stack direction={'row'} alignItems={'center'} columnGap={1} py={1}>
              {
                  !isCompact &&
                  <ProfileImageViewer
                      imgPath={selectedClub?.imageUrl}
                      size={'xs'}
                  />
              }
              {
                  !collapsed &&
                  <Typography
                      variant={isCompact ? 'caption' : 'body1'}
                      fontWeight={500}
                      color={isInverted ? '#fff' : 'text.primary'}
                      lineHeight={1}
                  >
                    {selectedClub?.name}
                  </Typography>
              }
            </Stack>

            {
                isAdmin && !collapsed && switcherClubs?.length > 1 &&
                <>
                  {
                      isCompact &&
                      <>
                        {!open && <ExpandMore color={'inherit'}/>}
                        {open && <ExpandLess color={'inherit'}/>}
                      </>
                  }
                  {
                      !isCompact &&
                      <UnfoldMore/>
                  }

                </>
            }
          </Stack>
          {
              isAdmin && switcherClubs?.length > 1 &&
              <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isCompact ? 'center' : 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: isCompact ? 'center' : 'left',
                  }}
              >
                <List disablePadding sx={{
                  minWidth: '200px',
                }}>
                  {
                    switcherClubs?.map((item, index) => {
                      return (
                          <ListItem
                              key={index}
                              disablePadding
                              onClick={() => handleChangeClub(item)}
                          >
                            <ListItemButton>
                              {
                                  !isCompact &&
                                  <ListItemIcon>
                                    <ProfileImageViewer
                                        imgPath={item?.imageUrl}
                                        size={'xxs'}
                                        placeholderStyle={'shield-icon'}
                                    />
                                  </ListItemIcon>
                              }
                              <ListItemText>
                                {item.name}
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                      );
                    })
                  }
                </List>
              </Popover>
          }
        </>
    );
  }
}

export default LayoutMainHeaderClubSelect;
