import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

function DialogSection({
                         label,
                         icon: Icon,
                         isDisabled = false,
                         isLoading = false,
                         isLastChild = false,
                         paddingY = '24px',
                         children,
                         isHideIcon = false,
                         altLabelSlot,
                       }) {

  const theme = useTheme();

  return <>
    <Box sx={{
      cursor: (!isLoading && isDisabled) ? 'not-allowed' : '',
      width: '100%',
    }}>
      <Box
          sx={{
            pointerEvents: (!isLoading && isDisabled) ? 'none' : '',
            width: '100%',
          }}
      >
        <Stack
            direction={'row'}
            spacing={1}
            width={'100%'}
            sx={{paddingY: paddingY}}
        >
          {
              isLoading && !!Icon &&
              <Skeleton variant={'circular'} width={'23px'} height={'20px'}/>
          }
          {
              !isLoading && !!Icon &&
              <Box color={theme.palette.grey[500]}>
                <Icon
                    color={'inherit'}
                    sx={{
                      opacity: isDisabled ? 0.4 : 1,
                      position: 'relative',
                      top: '2px',
                      visibility: isHideIcon ? 'hidden' : 'initial',
                    }}/>
              </Box>

          }
          <Stack
              width={'100%'}
              sx={{opacity: isDisabled ? 0.4 : 1}}
              spacing={.5}
          >
            {
                isLoading &&
                <Typography variant={'overline'}>
                  <Skeleton width={'60px'} height={'20px'}/>
                </Typography>
            }
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                width={'100%'}
                sx={{opacity: isDisabled ? 0.4 : 1}}
            >
              {
                  !isLoading &&
                  <Typography
                      variant={'overline'}
                      sx={{
                        lineHeight: '31.92px',
                        mb: '4px',
                      }}
                  >
                    {label}
                  </Typography>
              }
              {
                  !isLoading &&
                  altLabelSlot
              }
            </Stack>
            {children}
          </Stack>
        </Stack>
        {
            !isLoading && !isLastChild && <Divider/>
        }
        {
            isLoading && !isLastChild &&
            <Skeleton width={'100%'} height={'2px'}/>
        }
      </Box>
    </Box>
  </>;
}

export default DialogSection;
