import DnDArea from '../DnD/DnDArea';
import {useController, useFormContext} from 'react-hook-form';
import {useState} from 'react';
import {fileUpload_POST} from '../../../api/file-upload-delete';
import {getIsArray, getIsObject} from '../../../utils/types';

function RHFFileUpload({
                         name,
                         accept = 'img' || 'pdf' || 'all',
                         rules,
                         render,
                       }) {

  const {control, getValues} = useFormContext();

  const {
    field: {onChange, value},
    fieldState: {error},
  } = useController({
    name,
    control,
    rules,
  });

  const [isLoading, setIsLoading] = useState(false);

  async function handleDrop(acceptedFiles) {

    setIsLoading(true);

    const files = acceptedFiles?.map(file => Object.assign(file, {
      url: URL.createObjectURL(file),
    }));

    await fileUpload_POST({files, cbSuccess, cbFail});

    function cbSuccess(res) {

      const response = res?.data?.data;

      const isObject = getIsObject(response);
      const isArray = getIsArray(response);

      let newFiles;

      if (isObject) {
        newFiles = [response];
      }

      if (isArray) {
        newFiles = response;
      }

      // Append to existing files

      let existingFiles = getValues(name);

      let output = [
        ...(existingFiles || []),
        ...newFiles,
      ];

      // Update control

      onChange(output);

      setIsLoading(false);
    }

    function cbFail(e) {
      setIsLoading(false);
    }


  }

  return (
      <DnDArea onDrop={handleDrop} accept={accept} disabled={isLoading}>
        {render && render({isLoading})}
      </DnDArea>
  );
}

export default RHFFileUpload;
