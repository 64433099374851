import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useTranslate} from '@tolgee/react';

function TournamentDetailActions({details}) {

  const {t} = useTranslate();
  const navigateTo = useNavigate();

  const {id: tournamentId} = details || {};

  return (
      <>
        <Button
            variant="outlined"
            onClick={() => {
              navigateTo('/tournaments');
            }}
        >
          {t('global.buttons.actions.back')}
        </Button>
        <Button
            variant="contained"
            onClick={() => {
              navigateTo(`/tournaments/edit/${tournamentId}`);
            }}
        >
          {t('global.buttons.actions.edit')}
        </Button>
      </>
  );
}

export default TournamentDetailActions;
