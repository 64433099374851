import axios from 'axios';

export async function clubRatingsSystem_GET({
                                              clubId,
                                              sports,
                                              cbSuccess,
                                              cbFail,
                                            }) {
  try {
    const url = `clubs/rating-systems/${clubId}?sports=${sports}`;
    const response = await axios.get(url);
    const normalized = normalize(response?.data?.data);
    cbSuccess(normalized);
  } catch (e) {
    cbFail(e);
  }

}

function normalize(data) {
  const result = data.reduce((acc, item) => {
    item.clubRatingSystems.forEach(({sport}) => {
      if (!acc[sport]) {
        acc[sport] = {
          min: Infinity,
          max: -Infinity,
        };
      }

      const ratings = item.detail.map(d => d.rating);
      const minRating = Math.min(...ratings);
      const maxRating = Math.max(...ratings);

      acc[sport].min = Math.min(acc[sport].min, minRating);
      acc[sport].max = Math.max(acc[sport].max, maxRating);
    });

    return acc;
  }, {});

  return result;
}

